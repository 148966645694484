import {
	IAddSocietyDeviceRequestData,
	ICreateSocietySettingsRequestData,
	ISocietySettingsResponseData,
	ICreateSocietyUserRequestData,
	IEditSocietyDeviceRequestData,
	IEditSocietyUserRequestData,
	IGetCustomersMetricsResponse,
	IGetCustomersRequestData,
	IGetCustomersResponse,
	IGetSocietyDevicesRequestData,
	IGetSocietyDevicesResponse,
	IGetSocietyUsersRequestData,
	IGetSocietyUsersResponse,
	IRenewSubscriptionRequestData,
	IEditSocietySettingsRequestData,
	IDisableSubscriptionRequestData
} from "../../types";
import { getAPIHeaderOptions, getDefaultParams, transformErrorResponse } from "../../utils/apiUtils";
import { queriesApi } from "./queries.reducer";

export const customersApi = queriesApi.injectEndpoints({
	endpoints: (builder) => ({
		// GET CUSTOMERS
		getCustomers: builder.query<IGetCustomersResponse, IGetCustomersRequestData>({
			query: (requestData) => ({
				url: "/analytics/admin/customer",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: { ...getDefaultParams(), ...requestData }
			})
		}),

		// GET CUSTOMERS METRICS
		getCustomersMetrics: builder.query<IGetCustomersMetricsResponse, void>({
			query: () => ({
				url: "/analytics/admin/customer-metrics",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: getDefaultParams()
			})
		}),

		// GET SOCIETY DEVICES
		getSocietyDevices: builder.query<IGetSocietyDevicesResponse, IGetSocietyDevicesRequestData>({
			query: (requestData) => ({
				url: "/devices/admin/device-data",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: { ...getDefaultParams(), ...requestData }
			})
		}),

		// ADD SOCIETY DEVICE
		addSocietyDevice: builder.mutation<unknown, IAddSocietyDeviceRequestData>({
			query: (requestData) => ({
				url: "/devices/admin/device-data",
				headers: getAPIHeaderOptions(true),
				method: "POST",
				body: requestData
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// GET SOCIETY USERS
		getSocietyUsers: builder.query<IGetSocietyUsersResponse, IGetSocietyUsersRequestData>({
			query: (requestData) => ({
				url: "/auth/admin/users-data",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: { ...getDefaultParams(), ...requestData }
			})
		}),

		// CREATE SOCIETY USER
		createSocietyUser: builder.mutation<unknown, ICreateSocietyUserRequestData>({
			query: (requestData) => ({
				url: "/auth/admin/users-data",
				headers: getAPIHeaderOptions(true),
				method: "POST",
				body: requestData
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// CREATE SOCIETY SETTINGS
		createSocietySettings: builder.mutation<ISocietySettingsResponseData, ICreateSocietySettingsRequestData>({
			query: (requestData) => ({
				url: "/analytics/settings",
				headers: getAPIHeaderOptions(true),
				method: "POST",
				body: requestData
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// EDIT SOCIETY SETTINGS
		editSocietySettings: builder.mutation<ISocietySettingsResponseData, IEditSocietySettingsRequestData>({
			query: (requestData) => ({
				url: `/analytics/settings/${requestData.id}`,
				headers: getAPIHeaderOptions(true),
				method: "PATCH",
				body: requestData.data
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// EDIT SOCIETY DEVICE
		editSocietyDevice: builder.mutation<unknown, IEditSocietyDeviceRequestData>({
			query: (requestData) => ({
				url: `/devices/admin/device-data/${requestData.id}`,
				headers: getAPIHeaderOptions(true),
				method: "PATCH",
				body: requestData.data
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// EDIT SOCIETY USER
		editSocietyUser: builder.mutation<unknown, IEditSocietyUserRequestData>({
			query: (requestData) => ({
				url: `/auth/admin/users-data/${requestData.id}`,
				headers: getAPIHeaderOptions(true),
				method: "PATCH",
				body: requestData.data
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// RENEW SUBSCRIPTION
		renewSubscription: builder.mutation<unknown, IRenewSubscriptionRequestData>({
			query: (requestData) => ({
				url: `/analytics/admin/renew-subscription/${requestData.id}`,
				headers: getAPIHeaderOptions(true),
				method: "PATCH",
				body: requestData.data
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// DISABLE SUBSCRIPTION
		disableSubscription: builder.mutation<unknown, IDisableSubscriptionRequestData>({
			query: (requestData) => ({
				url: `/analytics/admin/disable-subscription/${requestData.id}`,
				headers: getAPIHeaderOptions(true),
				method: "PATCH",
				body: requestData.data
			}),
			transformErrorResponse: transformErrorResponse
		})
	})
});

export const {
	// GET CUSTOMERS
	useGetCustomersQuery,
	// GET CUSTOMERS METRICS
	useGetCustomersMetricsQuery,
	// GET SOCIETY DEVICES
	useGetSocietyDevicesQuery,
	// ADD SOCIETY DEVICE
	useAddSocietyDeviceMutation,
	// GET SOCIETY USERS
	useGetSocietyUsersQuery,
	// CREATE SOCIETY USER
	useCreateSocietyUserMutation,
	// CREATE SOCIETY SETTINGS
	useCreateSocietySettingsMutation,
	// EDIT SOCIETY DEVICE
	useEditSocietyDeviceMutation,
	// EDIT SOCIETY USER
	useEditSocietyUserMutation,
	// RENEW SUBSCRIPTION
	useRenewSubscriptionMutation,
	// EDIT SOCIETY SETTINGS
	useEditSocietySettingsMutation,
	// DISABLE SUBSCRIPTION
	useDisableSubscriptionMutation
} = customersApi;
