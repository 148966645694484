import { Box, Button } from "@mui/material";
import React, { FC, ReactNode, useEffect, useState } from "react";
import ConstructionIcon from "@mui/icons-material/Construction";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";

import "./societyConfiguration.css";
import BuildingConfigurationComponent from "./BuildingConfigurationComponent";
import CustomAreasComponent from "./CustomAreasComponent";
import { IBuildingDetails, IEditBuildingConfigurationDetails, ISocietyConfigDetails } from "../../types";
import { useEditSocietyConfigMutation } from "../../redux/reducers/settings.reducer";
import { BUILDING_TYPES_E, CUSTOM_ENTRY_BUILDING_NAME } from "../../utils/constants";
import { useAppDispatch } from "../../redux";
import { showFeedbackNotification } from "../../redux/reducers/feedbackNotification.reducer";

type SocietyConfigurationSelectedTabType = "building_config" | "custom_area";

interface ISocietyConfigurationProps {
	societyDetails: ISocietyConfigDetails | null;
	showEditActions?: boolean;
	onSubmit: () => void;
	onCancel: () => void;
	onChangeSocietyDetails: (societyDetails: ISocietyConfigDetails) => void;
}

const SocietyConfiguration: FC<ISocietyConfigurationProps> = (props) => {
	const { societyDetails, showEditActions, onSubmit, onCancel, onChangeSocietyDetails } = props;

	const dispatch = useAppDispatch();

	const [selectedTab, setSelectedTab] = useState<SocietyConfigurationSelectedTabType>("building_config");
	const [buildingsList, setBuildingsList] = useState<IBuildingDetails[]>([]);
	const [customEntriesList, setCustomEntriesList] = useState<string[]>([]);

	const [initialBuildingsList, setInitialBuildingsList] = useState<IBuildingDetails[]>([]);
	const [initialCustomEntriesList, setInitialCustomEntriesList] = useState<string[]>([]);

	// APIS
	const [
		editBuildingConfiguration,
		{
			data: editBuildingConfigResponse,
			isLoading: editBuildingConfigLoading,
			isSuccess: editBuildingConfigSuccess,
			error: editBuildingConfigError,
			reset: resetEditBuildingConfigState
		}
	] = useEditSocietyConfigMutation();

	function handleChangeSelectedTab(newTab: SocietyConfigurationSelectedTabType) {
		setSelectedTab(newTab);
	}

	function handleSubmitBuildingConfiguration(updatedData?: { buildings?: IBuildingDetails[]; customArea?: string[] }) {
		if (!societyDetails) return;

		const finalBuildingConfig: IEditBuildingConfigurationDetails[] = [];

		if (updatedData && updatedData.buildings) {
			for (const buildingItem of updatedData.buildings) {
				finalBuildingConfig.push({
					name: buildingItem.name,
					building_type: buildingItem.building_type,
					entries: buildingItem.entries.map((entryItem) =>
						entryItem.replace(buildingItem.name, "").replace(buildingItem.delimiter, "")
					),
					delimiter: buildingItem.delimiter,
					flats: buildingItem.flats_per_floor,
					floors: buildingItem.number_of_floors,
					show_floor_number: buildingItem.show_floor_number
				});
			}
		} else {
			for (const buildingItem of buildingsList) {
				finalBuildingConfig.push({
					name: buildingItem.name,
					building_type: buildingItem.building_type,
					entries: buildingItem.entries.map((entryItem) =>
						entryItem.replace(buildingItem.name, "").replace(buildingItem.delimiter, "")
					),
					delimiter: buildingItem.delimiter,
					flats: buildingItem.flats_per_floor,
					floors: buildingItem.number_of_floors,
					show_floor_number: buildingItem.show_floor_number
				});
			}
		}

		if (updatedData && updatedData.customArea) {
			if (updatedData.customArea.length > 0) {
				finalBuildingConfig.push({
					name: CUSTOM_ENTRY_BUILDING_NAME,
					building_type: BUILDING_TYPES_E.CUSTOM_ENTRIES,
					entries: updatedData.customArea
				});
			}
		} else {
			if (customEntriesList.length > 0) {
				finalBuildingConfig.push({
					name: CUSTOM_ENTRY_BUILDING_NAME,
					building_type: BUILDING_TYPES_E.CUSTOM_ENTRIES,
					entries: customEntriesList
				});
			}
		}

		if (finalBuildingConfig.length > 0) {
			editBuildingConfiguration({
				id: societyDetails.id,
				data: {
					building_config: finalBuildingConfig
				}
			});
		}
	}

	function handleDeleteBuilding(index: number) {
		if (index >= 0) {
			const updatedBuildingsList = [...buildingsList];
			updatedBuildingsList.splice(index, 1);
			setBuildingsList(updatedBuildingsList);

			handleSubmitBuildingConfiguration({ buildings: updatedBuildingsList });
		}
	}

	function handleSaveBuildingDetails(index: number, updatedBuildingDetails: IBuildingDetails) {
		if (index >= 0) {
			const updatedBuildingsList = [...buildingsList];
			updatedBuildingsList[index] = updatedBuildingDetails;
			setBuildingsList(updatedBuildingsList);

			handleSubmitBuildingConfiguration({ buildings: updatedBuildingsList });
		}
	}

	function handleAddNewBuilding() {
		const updatedBuildingsList = [...buildingsList];

		const updatedLength = updatedBuildingsList.push({
			name: "New Building",
			building_type: 0,
			entries: [],
			number_of_floors: "",
			flats_per_floor: "",
			new_building: true,
			delimiter: "-",
			show_floor_number: true
		});

		setBuildingsList(updatedBuildingsList);

		return updatedLength - 1;
	}

	function handleUpdateCustomEntries(updatedEntries: string[]) {
		setCustomEntriesList(updatedEntries);
	}

	function handleSubmitCustomEntries() {
		handleSubmitBuildingConfiguration();
	}

	useEffect(() => {
		if (editBuildingConfigResponse) {
			onChangeSocietyDetails(editBuildingConfigResponse);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editBuildingConfigResponse]);

	useEffect(() => {
		if (societyDetails) {
			const updatedBuildingsList: IBuildingDetails[] = [];
			const updatedCustomEntriesList: string[] = [];

			const updatedInitialBuildingsList: IBuildingDetails[] = [];
			const updatedInitialCustomEntriesList: string[] = [];

			for (const buildingItem of societyDetails.building_config) {
				if (buildingItem.building_type === BUILDING_TYPES_E.BUILDING) {
					updatedBuildingsList.push({
						name: buildingItem.name,
						building_type: buildingItem.building_type,
						entries: buildingItem.entries.map(
							(entryItem) => `${buildingItem.name}${buildingItem.delimiter ?? ""}${entryItem}`
						),
						number_of_floors: buildingItem.floors,
						flats_per_floor: buildingItem.flats,
						new_building: false,
						delimiter: buildingItem.delimiter,
						show_floor_number: buildingItem.show_floor_number
					});

					updatedInitialBuildingsList.push({
						name: buildingItem.name,
						building_type: buildingItem.building_type,
						entries: buildingItem.entries.map(
							(entryItem) => `${buildingItem.name}${buildingItem.delimiter ?? ""}${entryItem}`
						),
						number_of_floors: buildingItem.floors,
						flats_per_floor: buildingItem.flats,
						new_building: false,
						delimiter: buildingItem.delimiter,
						show_floor_number: buildingItem.show_floor_number
					});
				} else if (buildingItem.building_type === BUILDING_TYPES_E.CUSTOM_ENTRIES) {
					updatedCustomEntriesList.push(...buildingItem.entries);
					updatedInitialCustomEntriesList.push(...buildingItem.entries);
				}
			}

			if (updatedBuildingsList.length > 0) {
				setBuildingsList(updatedBuildingsList);
			} else {
				setBuildingsList([
					{
						name: "New Building",
						building_type: BUILDING_TYPES_E.BUILDING,
						entries: [],
						flats_per_floor: "",
						number_of_floors: "",
						new_building: true,
						delimiter: "-",
						show_floor_number: true
					}
				]);
			}
			setCustomEntriesList(updatedCustomEntriesList);

			setInitialBuildingsList([...updatedInitialBuildingsList]);
			setInitialCustomEntriesList([...updatedInitialCustomEntriesList]);
		}
	}, [societyDetails]);

	useEffect(() => {
		if (editBuildingConfigSuccess) {
			resetEditBuildingConfigState();
			// refetchSocietyConfig();
			// dispatch(
			// 	showFeedbackNotification({
			// 		message: "Society Configuration updated successfully",
			// 		severity: "success"
			// 	})
			// );

			// onSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editBuildingConfigSuccess]);

	useEffect(() => {
		if (editBuildingConfigError) {
			dispatch(
				showFeedbackNotification({
					message:
						"data" in editBuildingConfigError && editBuildingConfigError.data
							? String(editBuildingConfigError.data)
							: "Something went wrong",
					severity: "error"
				})
			);

			resetEditBuildingConfigState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editBuildingConfigError]);

	const RenderSelectedTabContent: { [key in SocietyConfigurationSelectedTabType]: ReactNode } = {
		building_config: (
			<BuildingConfigurationComponent
				buildingsList={buildingsList}
				initialBuildingsList={initialBuildingsList}
				saveButtonLoading={editBuildingConfigLoading}
				smallWidth={showEditActions}
				onDeleteBuilding={handleDeleteBuilding}
				onSaveBuilding={handleSaveBuildingDetails}
				onAddBuilding={handleAddNewBuilding}
			/>
		),
		custom_area: (
			<CustomAreasComponent
				customAreasList={customEntriesList}
				initialCustomAreasList={initialCustomEntriesList}
				saveButtonLoading={editBuildingConfigLoading}
				smallWidth={showEditActions}
				onUpdateCustomEntries={handleUpdateCustomEntries}
				onSave={handleSubmitCustomEntries}
			/>
		)
	};

	return (
		<Box className="society-configuration-screen-wrapper">
			<Box className="society-configuration-screen-tabs-wrapper">
				<Button
					disableElevation
					color="primary"
					variant={selectedTab === "building_config" ? "contained" : "outlined"}
					startIcon={<ConstructionIcon />}
					onClick={() => handleChangeSelectedTab("building_config")}
				>
					Building Configuration
				</Button>

				<Button
					disableElevation
					color="primary"
					variant={selectedTab === "custom_area" ? "contained" : "outlined"}
					startIcon={<DashboardCustomizeIcon />}
					sx={{ paddingX: 4 }}
					onClick={() => handleChangeSelectedTab("custom_area")}
				>
					Custom Areas
				</Button>
			</Box>

			{RenderSelectedTabContent[selectedTab]}

			{showEditActions ? (
				<Box className="add-customer-form-actions-wrapper center-align">
					{/* <Button variant="outlined" color="error" sx={{ paddingX: 6 }} onClick={onCancel}>
						Cancel
					</Button> */}

					<Button disableElevation variant="contained" color="success" onClick={onSubmit}>
						Save
					</Button>
				</Box>
			) : (
				<Box className="add-customer-form-actions-wrapper">
					<Button variant="outlined" color="error" sx={{ paddingX: 6 }} onClick={onCancel}>
						Back
					</Button>

					<Button disableElevation variant="contained" color="primary" onClick={onSubmit}>
						Save and Next
					</Button>
				</Box>
			)}
		</Box>
	);
};

export default SocietyConfiguration;
