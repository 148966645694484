import {
	ICreateSystemIntegratorRequestData,
	IEditSystemIntegratorRequestData,
	IGetSystemIntegratorsMetricsResponse,
	IGetSystemIntegratorsRequestData,
	IGetSystemIntegratorsResponse
} from "../../types";
import { getAPIHeaderOptions, getDefaultParams, transformErrorResponse } from "../../utils/apiUtils";
import { queriesApi } from "./queries.reducer";

export const systemIntegratorsApi = queriesApi.injectEndpoints({
	endpoints: (builder) => ({
		// GET SYSTEM INTEGRATORS
		getSystemIntegrators: builder.query<IGetSystemIntegratorsResponse, IGetSystemIntegratorsRequestData>({
			query: (requestData) => ({
				url: "/system-integrators/system-integrator",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: { ...getDefaultParams(), ...requestData }
			})
		}),

		// GET SYSTEM INTEGRATORS METRICS
		getSystemIntegratorsMetrics: builder.query<IGetSystemIntegratorsMetricsResponse, void>({
			query: () => ({
				url: "/system-integrators/admin/system-integrator-metrics",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: getDefaultParams()
			})
		}),

		// CREATE SYSTEM INTEGRATOR
		createSystemIntegrator: builder.mutation<unknown, ICreateSystemIntegratorRequestData>({
			query: (requestData) => ({
				url: "/system-integrators/system-integrator",
				headers: getAPIHeaderOptions(true),
				method: "POST",
				body: { ...requestData, ...getDefaultParams() }
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// EDIT SYSTEM INTEGRATOR
		editSystemIntegrator: builder.mutation<unknown, IEditSystemIntegratorRequestData>({
			query: (requestData) => ({
				url: `/system-integrators/system-integrator/${requestData.id}`,
				headers: getAPIHeaderOptions(true),
				method: "PATCH",
				body: { ...requestData.data, ...getDefaultParams() }
			}),
			transformErrorResponse: transformErrorResponse
		})
	})
});

export const {
	// GET SYSTEM INTEGRATORS
	useGetSystemIntegratorsQuery,
	// GET SYSTEM INTEGRATORS METRICS
	useGetSystemIntegratorsMetricsQuery,
	// CREATE SYSTEM INTEGRATOR
	useCreateSystemIntegratorMutation,
	// EDIT SYSTEM INTEGRATOR
	useEditSystemIntegratorMutation
} = systemIntegratorsApi;
