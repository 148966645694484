import CryptoJS from "crypto-js";
import { store } from "../redux";
import { pick } from "lodash";
import { convertSnakeToTitleCase } from "./commonUtils";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

type NestedObjectType = {
	[key in string]: NestedObjectType | any;
};

type FlattenedObjectType = {
	[key in string]: any;
};

// export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL ?? "";
export const BASE_API_URL = `${window.location.protocol}//${window.location.hostname}/api/v1`;

// API AUTH TOKEN
const API_REQUEST_HEADER_OPTIONS = {
	// "X-Cue": "34db55e07f7b39df480284397f7f42ec",
	// "X-Salt": "683239",
	// "X-Alpha": "21"
};

function getAuthToken() {
	const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY ? String(process.env.REACT_APP_ENCRYPTION_KEY) : "";
	const encryptedToken: string = store.getState().auth.user.token;

	const token = CryptoJS.AES.decrypt(encryptedToken, encryptionKey).toString(CryptoJS.enc.Utf8);
	return token ? `token ${token}` : "";
}

export const getAPIHeaderOptions = (isAuthRequired = false) => {
	const token = getAuthToken();

	if (isAuthRequired && token) {
		return {
			...API_REQUEST_HEADER_OPTIONS,
			Authorization: token
		};
	}

	return API_REQUEST_HEADER_OPTIONS;
};

export const getDefaultParams = () => {
	const societyId = store.getState().auth.user.society;

	if (typeof societyId === "number" && societyId >= 0) {
		return { society: societyId };
	}

	return {};
};

function flattenObject(object: NestedObjectType, parentKey?: string): FlattenedObjectType {
	let result: FlattenedObjectType = {};

	for (const [key, value] of Object.entries(object)) {
		if (typeof value === "object" && !Array.isArray(value)) {
			const flattenedValue = flattenObject(value, parentKey ? `${parentKey}.${key}` : key);

			result = {
				...result,
				...flattenedValue
			};

			continue;
		}

		if (parentKey) result[`${parentKey}.${key}`] = value;
		else result[key] = value;
	}

	return result;
}

function extractErrorMessage(error: FlattenedObjectType, parentKey?: string): string {
	if ("detail" in error) error = pick(error, ["detail"]);
	else if ("details" in error) error = pick(error, ["details"]);

	const errorKey = Object.keys(error)[0];
	const errorValue = Object.values(error)[0] ?? "Something went wrong";

	if (typeof errorValue === "object") {
		return extractErrorMessage(errorValue, errorKey);
	}

	const errorMessage = String(errorValue);

	if (errorMessage.toLowerCase().includes("this field")) {
		return errorMessage.replace(/this field/i, convertSnakeToTitleCase(parentKey || errorKey || "This field"));
	}

	if (errorMessage.toLowerCase().includes("this value")) {
		return errorMessage.replace(/this value/i, convertSnakeToTitleCase(parentKey || errorKey || "This value"));
	}

	return errorMessage;
}

export const transformErrorResponse = (response: FetchBaseQueryError): FetchBaseQueryError => {
	if (response.data) {
		const error = flattenObject(response.data);
		response.data = extractErrorMessage(error);
	} else {
		response.data = "Something went wrong";
	}

	return response;
};
