export const convertToTitleCase = (text: string): string => {
	if (!text) return "";

	return text
		.toLowerCase()
		.split(" ")
		.map((word) => word.replace(word[0], word[0].toUpperCase()))
		.join(" ");
};

export const convertSnakeToTitleCase = (text: string): string => {
	if (!text) return "";

	return text
		.toLowerCase()
		.split("_")
		.map((word) => word.replace(word[0], word[0].toUpperCase()))
		.join(" ");
};

export const downloadImageFromUrl = (imageUrl: string, fileName: string) => {
	const downloadLinkElement = document.createElement("a");

	downloadLinkElement.href = imageUrl;
	downloadLinkElement.download = fileName;

	document.body.appendChild(downloadLinkElement);
	downloadLinkElement.click();

	downloadLinkElement.parentNode?.removeChild(downloadLinkElement);
};
