import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridColDef, GridPagination, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { ROWS_PER_PAGE } from "../../../utils/constants";
import { ISocietyConfigDetails } from "../../../types";
import TablePagination from "../../../components/TablePagination";
import CustomerContactDetailsDialog from "./CustomerContactDetailsDialog";
import { useEditSocietyConfigMutation } from "../../../redux/reducers/settings.reducer";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "../../../redux";
import { showFeedbackNotification } from "../../../redux/reducers/feedbackNotification.reducer";

interface IContactDetailsProps {
	societyDetails: ISocietyConfigDetails | null;
	onSubmit: () => void;
	onCancel: () => void;
	onChangeSocietyDetails: (societyDetails: ISocietyConfigDetails) => void;
}

export interface ISocietyContactDetails {
	id: number;
	first_name: string;
	last_name: string;
	contact_number: string;
	// role: string;
	designation: string;
	email: string;
	is_subscribed: boolean;
}

const ContactsListPagination = (props: any) => (
	<GridPagination
		{...props}
		ActionsComponent={TablePagination}
		labelDisplayedRows={() => <></>}
		classes={{ spacer: "grid-pagination-spacer-custom" }}
	/>
);

const ContactDetails: FC<IContactDetailsProps> = (props) => {
	const { societyDetails, onSubmit, onCancel, onChangeSocietyDetails } = props;

	const dispatch = useAppDispatch();

	const [contacts, setContacts] = useState<ISocietyContactDetails[]>([]);
	const [showContactDetailsDialog, setShowContactDetailsDialog] = useState<boolean>(false);
	const [selectedContactDetails, setSelectedContactDetails] = useState<ISocietyContactDetails | null>(null);

	// APIS
	const [
		editSocietyConfig,
		{
			data: editSocietyConfigResponse,
			isLoading: editSocietyConfigLoading,
			isSuccess: editSocietyConfigSuccess,
			error: editSocietyConfigError,
			reset: resetEditSocietyConfigState
		}
	] = useEditSocietyConfigMutation();

	function handleOpenContactDetails(contactDetails: ISocietyContactDetails | null) {
		setSelectedContactDetails(contactDetails);
		setShowContactDetailsDialog(true);
	}

	function handleCloseContactDetails() {
		setShowContactDetailsDialog(false);
		setSelectedContactDetails(null);
	}

	function handleSubmitContactDetails(contactDetails: ISocietyContactDetails) {
		setContacts((currentContacts) => {
			const updatedContacts = [...currentContacts];

			if (contactDetails.id < 0) {
				updatedContacts.push({ ...contactDetails, id: updatedContacts.length });
			} else {
				const index = updatedContacts.findIndex((item) => item.id === contactDetails.id);
				if (index >= 0) updatedContacts[index] = { ...contactDetails };
			}
			return updatedContacts;
		});

		handleCloseContactDetails();
	}

	function handleDeleteContact(id: number) {
		setContacts((currentContacts) => {
			const updatedContacts = [...currentContacts];

			const contactIndex = updatedContacts.findIndex((item) => item.id === id);
			if (contactIndex >= 0) {
				updatedContacts.splice(contactIndex, 1);
			}

			return updatedContacts;
		});

		handleCloseContactDetails();
	}

	function handleSaveButtonClick() {
		if (societyDetails) {
			editSocietyConfig({
				id: societyDetails.id,
				data: {
					contact_details: contacts.map((item) => ({
						first_name: item.first_name,
						last_name: item.last_name,
						contact_number: item.contact_number,
						// role: item.role,
						designation: item.designation,
						is_primary_user: false,
						email_address: item.email,
						is_subscribed: item.is_subscribed
					}))
				}
			});
		}
	}

	const columnsData: GridColDef[] = [
		{
			field: "name",
			headerName: "Name",
			flex: 1,
			minWidth: 150,
			sortable: false,
			valueGetter: (params: GridValueGetterParams<ISocietyContactDetails>) =>
				`${params.row.first_name} ${params.row.last_name}`
		},
		// {
		// 	field: "role",
		// 	headerName: "Role",
		// 	flex: 1,
		// 	minWidth: 150,
		// 	sortable: false
		// 	// valueFormatter: (params: GridValueFormatterParams<string>) =>
		// 	// 	convertToTitleCase(CUSTOMER_ROLE_E[Number(params.value)])
		// },
		{
			field: "designation",
			headerName: "Designation",
			flex: 1,
			minWidth: 150,
			sortable: false
		},
		{
			field: "contact_number",
			headerName: "Contact Number",
			flex: 1,
			minWidth: 150,
			sortable: false
		},
		{
			field: "actions",
			headerName: "",
			width: 50,
			sortable: false,
			renderCell: (params: GridRenderCellParams<ISocietyContactDetails>) => (
				<Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
					<IconButton onClick={() => handleOpenContactDetails(params.row)}>
						<EditIcon />
					</IconButton>
				</Box>
			)
		}
	];

	useEffect(() => {
		if (societyDetails) {
			setContacts(
				societyDetails.contact_details.map((item, index) => ({
					id: index,
					first_name: item.first_name,
					last_name: item.last_name,
					contact_number: item.contact_number,
					// role: item.role,
					designation: item.designation,
					email: item.email_address,
					is_subscribed: item.is_subscribed
				}))
			);
		}
	}, [societyDetails]);

	useEffect(() => {
		if (editSocietyConfigSuccess && editSocietyConfigResponse) {
			resetEditSocietyConfigState();
			onSubmit();
			onChangeSocietyDetails(editSocietyConfigResponse);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editSocietyConfigResponse, editSocietyConfigSuccess, onSubmit, resetEditSocietyConfigState]);

	useEffect(() => {
		if (editSocietyConfigError) {
			dispatch(
				showFeedbackNotification({
					message:
						"data" in editSocietyConfigError && editSocietyConfigError.data
							? String(editSocietyConfigError.data)
							: "Something went wrong",
					severity: "error"
				})
			);

			resetEditSocietyConfigState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editSocietyConfigError]);

	return (
		<Box className="device-registration-screen-wrapper">
			<Box className="device-registration-header-wrapper">
				<Typography variant="h6" color="var(--color-primary-dark)">
					Customer Data
				</Typography>

				<Button
					variant="outlined"
					color="success"
					startIcon={<AddCircleIcon />}
					sx={{ paddingX: 3 }}
					onClick={() => handleOpenContactDetails(null)}
				>
					Add
				</Button>
			</Box>

			<Box className="device-registration-table-wrapper">
				<DataGrid
					columns={columnsData}
					rows={contacts}
					disableRowSelectionOnClick
					disableColumnMenu
					rowSpacingType="border"
					density="compact"
					columnHeaderHeight={80}
					pageSizeOptions={[ROWS_PER_PAGE]}
					slots={{ pagination: ContactsListPagination }}
					hideFooter={contacts.length <= ROWS_PER_PAGE}
					slotProps={{ footer: { sx: { justifyContent: "center" } } }}
					// paginationMode="server"
					// paginationModel={{ page: filters.page - 1, pageSize: ROWS_PER_PAGE }}
					// onPaginationModelChange={handlePageChange}
					// rowCount={getSocietyDevicesResponse?.count ?? 0}
					// loading={getSocietyDevicesLoading || getSocietyDevicesFetching}
					classes={{
						columnSeparator: "table-column-separator",
						columnHeader: `table-column-header hide-last-separator`,
						root: "table-root"
					}}
				/>
			</Box>

			<Box className="add-customer-form-footer">
				<Button variant="text" color="error" sx={{ paddingX: 5 }} onClick={onSubmit}>
					Skip
				</Button>

				<Box className="add-customer-form-actions-wrapper">
					<Button variant="outlined" color="error" sx={{ paddingX: 6 }} onClick={onCancel}>
						Back
					</Button>

					<LoadingButton
						disableElevation
						variant="contained"
						color="primary"
						onClick={handleSaveButtonClick}
						loading={editSocietyConfigLoading}
					>
						Save and Next
					</LoadingButton>
				</Box>
			</Box>

			<CustomerContactDetailsDialog
				open={showContactDetailsDialog}
				contactDetails={selectedContactDetails}
				contactsList={contacts}
				onClose={handleCloseContactDetails}
				onSubmit={handleSubmitContactDetails}
				onDelete={handleDeleteContact}
			/>
		</Box>
	);
};

export default ContactDetails;
