import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography
} from "@mui/material";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleIcon from "@mui/icons-material/AddCircle";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// import CloseIcon from "@mui/icons-material/Close";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { LoadingButton } from "@mui/lab";
import AddFlatDialog from "./AddFlatDialog";
import { IBuildingDetails } from "../../types";
import { useAppDispatch } from "../../redux";
import { BUILDING_TYPES_E } from "../../utils/constants";
import { showFeedbackNotification } from "../../redux/reducers/feedbackNotification.reducer";
import NumberInput from "../NumberInput";
import { convertToTitleCase } from "../../utils/commonUtils";
import { isEqual } from "lodash";

interface IBuildingConfigurationComponentProps {
	buildingsList: IBuildingDetails[];
	initialBuildingsList: IBuildingDetails[];
	saveButtonLoading?: boolean;
	smallWidth?: boolean;
	onDeleteBuilding: (index: number) => void;
	onSaveBuilding: (index: number, updatedBuildingDetails: IBuildingDetails) => void;
	onAddBuilding: () => number;
}

interface ISelectedBuildingInputs {
	name: string;
	floors: string;
	flats: string;
	entries: string[];
	delimiter: string;
	showFloorNumber: boolean;
}

type IBuildingInputErrors = {
	[key in keyof Omit<ISelectedBuildingInputs, "entries" | "showFloorNumber">]: string;
};

const NEW_BUILDING_NAME = "New Building";

const BuildingConfigurationComponent: FC<IBuildingConfigurationComponentProps> = (props) => {
	const { buildingsList, initialBuildingsList, saveButtonLoading, smallWidth, onSaveBuilding, onAddBuilding } = props;

	const dispatch = useAppDispatch();

	const buildingsListRef = useRef<HTMLDivElement>(null);

	const [selectedBuildingIndex, setSelectedBuildingIndex] = useState<number>(0);
	// const [selectedEntriesIndex, setSelectedEntriesIndex] = useState<number[]>([]);
	// const [flatSelectionEnabled, setFlatSelectionEnabled] = useState<boolean>(false);
	// const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState<boolean>(false);
	const [showAddFlatDialog, setShowAddFlatDialog] = useState<boolean>(false);
	const [selectedBuildingInputs, setSelectedBuildingInputs] = useState<ISelectedBuildingInputs>({
		name: "",
		floors: "",
		flats: "",
		entries: [],
		delimiter: "-",
		showFloorNumber: true
	});

	const [buildingInputErrors, setBuildingInputErrors] = useState<IBuildingInputErrors>({
		name: "",
		floors: "",
		flats: "",
		delimiter: ""
	});

	const selectedBuildingInitialInputs = useMemo<ISelectedBuildingInputs>(() => {
		const initialBuildingDetails = initialBuildingsList[selectedBuildingIndex];

		if (initialBuildingDetails) {
			return {
				name: initialBuildingDetails.name,
				floors: initialBuildingDetails.number_of_floors,
				flats: initialBuildingDetails.flats_per_floor,
				entries: initialBuildingDetails.entries,
				delimiter: initialBuildingDetails.delimiter,
				showFloorNumber: initialBuildingDetails.show_floor_number
			};
		}

		return {
			name: "",
			floors: "",
			flats: "",
			entries: [],
			delimiter: "-",
			showFloorNumber: true
		};
	}, [initialBuildingsList, selectedBuildingIndex]);

	const IsInputDisabled = useMemo(() => {
		if (buildingsList.length <= 0) return true;
		return false;
	}, [buildingsList]);

	const IsSaveButtonDisabled = useMemo<boolean>(() => {
		const selectedBuildingDetails = buildingsList[selectedBuildingIndex];

		if (!selectedBuildingDetails) return true;
		// if (flatSelectionEnabled) return true;
		if (!selectedBuildingInputs.name || !selectedBuildingInputs.flats) return true;

		if (selectedBuildingInputs.showFloorNumber && !selectedBuildingInputs.floors) return true;

		if (selectedBuildingDetails.new_building) {
			if (selectedBuildingInputs.name === NEW_BUILDING_NAME) return true;
		}

		if (isEqual(selectedBuildingInputs, selectedBuildingInitialInputs)) return true;

		return false;
	}, [buildingsList, selectedBuildingIndex, selectedBuildingInitialInputs, selectedBuildingInputs]);

	function handleOpenAddFlatDialog() {
		setShowAddFlatDialog(true);
	}

	function handleCloseAddFlatDialog() {
		setShowAddFlatDialog(false);
	}

	// function handleOpenDeleteConfirmationDialog() {
	// 	setShowDeleteConfirmationDialog(true);
	// }

	// function handleCloseDeleteConfirmationDialog() {
	// 	setShowDeleteConfirmationDialog(false);
	// }

	// function handleSubmitDeleteBuilding() {
	// 	onDeleteBuilding(selectedBuildingIndex);
	// 	setSelectedBuildingIndex(0);
	// 	handleCloseDeleteConfirmationDialog();
	// }

	function handleScrollBuildingList(scrollTo: "left" | "right") {
		if (!buildingsListRef.current) return;

		const scrollValue = scrollTo === "left" ? -300 : scrollTo === "right" ? 300 : 0;

		buildingsListRef.current.scrollTo({ left: scrollValue, behavior: "smooth" });
	}

	function handleChangeSelectedBuilding(index: number) {
		setSelectedBuildingIndex(index);
		// setSelectedEntriesIndex([]);
	}

	function handleAddButtonClick() {
		setSelectedBuildingIndex(onAddBuilding());
	}

	// function handleEnableFlatSelection() {
	// 	setFlatSelectionEnabled(true);
	// }

	// function handleDisableFlatSelection() {
	// 	setFlatSelectionEnabled(false);
	// 	setSelectedEntriesIndex([]);
	// }

	function generateEntriesArray(
		floors: string,
		flats: string,
		buildingName: string,
		delimiter: string,
		showFloorNumber?: boolean
	): string[] {
		const numberOfFloors = floors && Number(floors) >= 0 ? Number(floors) : 0;
		const numberOfFlats = flats && Number(flats) >= 0 ? Number(flats) : 0;

		const floorsLength = floors.startsWith("0") ? floors.length : 1;
		const flatsLength = flats.length;

		const floorPrefix = [...Array(floorsLength)].fill(0).join("");
		const flatPrefix = [...Array(flatsLength)].fill(0).join("");

		const floorsArray = [...Array(numberOfFloors)].map((_, floorNumber) => {
			if (floorsLength >= 2) return `${floorPrefix}${floorNumber + 1}`.slice(floorsLength * -1);
			return String(floorNumber + 1);
		});

		const flatsArray = [...Array(numberOfFlats)].map((_, flatNumber) =>
			`${flatPrefix}${flatNumber + 1}`.slice(flatsLength * -1)
		);

		if (showFloorNumber) {
			return floorsArray
				.map((floorNumber) => flatsArray.map((flatNumber) => `${buildingName}${delimiter}${floorNumber}${flatNumber}`))
				.flat();
		}

		return flatsArray.map((flatNumber) => `${buildingName}${delimiter}${flatNumber}`);
	}

	function handleChangeSelectedBuildingInput(
		value: string,
		key: keyof Omit<ISelectedBuildingInputs, "entries" | "showFloorNumber">
	) {
		const updatedBuildingInputValues = { ...selectedBuildingInputs };

		updatedBuildingInputValues[key] = value;

		updatedBuildingInputValues.entries = generateEntriesArray(
			updatedBuildingInputValues.floors,
			updatedBuildingInputValues.flats,
			updatedBuildingInputValues.name,
			updatedBuildingInputValues.delimiter,
			updatedBuildingInputValues.showFloorNumber
		);

		setSelectedBuildingInputs(updatedBuildingInputValues);

		setBuildingInputErrors((currentBuildingInputErrors) => ({
			...currentBuildingInputErrors,
			[key]: ""
		}));
	}

	function handleChangeShowFlatNumberInput(isChecked: boolean) {
		const updatedBuildingInputValues = { ...selectedBuildingInputs };

		updatedBuildingInputValues.showFloorNumber = isChecked;

		updatedBuildingInputValues.entries = generateEntriesArray(
			updatedBuildingInputValues.floors,
			updatedBuildingInputValues.flats,
			updatedBuildingInputValues.name,
			updatedBuildingInputValues.delimiter,
			updatedBuildingInputValues.showFloorNumber
		);

		setSelectedBuildingInputs(updatedBuildingInputValues);
	}

	// function handleToggleEntrySelection(index: number) {
	// 	setSelectedEntriesIndex((currentSelectedEntriesIndex) => {
	// 		if (currentSelectedEntriesIndex.includes(index)) {
	// 			return currentSelectedEntriesIndex.filter((item) => item !== index);
	// 		}

	// 		const updatedSelectedEntriesIndex = [...currentSelectedEntriesIndex];
	// 		updatedSelectedEntriesIndex.push(index);
	// 		return updatedSelectedEntriesIndex;
	// 	});
	// }

	function handleSaveButtonClick() {
		const updatedBuildingInputErrors: IBuildingInputErrors = {
			name: "",
			floors: "",
			flats: "",
			delimiter: ""
		};

		if (!selectedBuildingInputs.name) {
			updatedBuildingInputErrors.name = "Building name is required";
		}

		if (selectedBuildingInputs.showFloorNumber && !selectedBuildingInputs.floors) {
			updatedBuildingInputErrors.floors = "Floors is required";
		}

		if (!selectedBuildingInputs.flats) {
			updatedBuildingInputErrors.flats = "Flats per floor is required";
		}

		setBuildingInputErrors(updatedBuildingInputErrors);

		if (updatedBuildingInputErrors.name || updatedBuildingInputErrors.floors || updatedBuildingInputErrors.flats) {
			return;
		}

		onSaveBuilding(selectedBuildingIndex, {
			name: selectedBuildingInputs.name,
			building_type: BUILDING_TYPES_E.BUILDING,
			entries: selectedBuildingInputs.entries,
			number_of_floors: selectedBuildingInputs.floors,
			flats_per_floor: selectedBuildingInputs.flats,
			new_building: false,
			delimiter: selectedBuildingInputs.delimiter,
			show_floor_number: selectedBuildingInputs.showFloorNumber
		});
	}

	// function handleRemoveButtonClick() {
	// 	setSelectedBuildingInputs((currentSelectedBuildingInputs) => ({
	// 		...currentSelectedBuildingInputs,
	// 		entries: currentSelectedBuildingInputs.entries.filter((_, index) => !selectedEntriesIndex.includes(index))
	// 	}));

	// 	setSelectedEntriesIndex([]);
	// 	handleDisableFlatSelection();
	// }

	function handleAddFlatSubmit(flatNumber: string) {
		const updatedBuildingInputs = { ...selectedBuildingInputs };

		if (updatedBuildingInputs.entries.includes(flatNumber)) {
			dispatch(
				showFeedbackNotification({
					message: "The entered flat number already exists in the building",
					severity: "warning"
				})
			);
			return;
		}

		updatedBuildingInputs.entries.push(
			`${convertToTitleCase(updatedBuildingInputs.name)}${updatedBuildingInputs.delimiter}${flatNumber}`
		);

		setSelectedBuildingInputs(updatedBuildingInputs);
		handleCloseAddFlatDialog();
	}

	useEffect(() => {
		if (buildingsList.length > 0 && selectedBuildingIndex >= 0) {
			const buildingDetails = buildingsList[selectedBuildingIndex];

			setSelectedBuildingInputs({
				name: buildingDetails.name,
				floors: buildingDetails.number_of_floors,
				flats: buildingDetails.flats_per_floor,
				entries: buildingDetails.entries,
				delimiter: buildingDetails.delimiter,
				showFloorNumber: buildingDetails.show_floor_number
			});
		}
	}, [buildingsList, selectedBuildingIndex]);

	return (
		<Box className="building-configuration-component-wrapper">
			<Box className="buildings-list-wrapper">
				<Box className="buildings-list-scroll-arrow left" onClick={() => handleScrollBuildingList("left")}>
					<ArrowLeftIcon />
				</Box>

				<Box className="buildings-list" ref={buildingsListRef}>
					{buildingsList.map((buildingItem, index) => (
						<Box className="buildings-list-item" key={index}>
							<Button
								disableElevation
								size="small"
								variant="contained"
								color="primary"
								startIcon={<ApartmentIcon />}
								sx={{ textTransform: "none" }}
								classes={{ root: selectedBuildingIndex !== index ? "inactive" : "" }}
								onClick={() => handleChangeSelectedBuilding(index)}
							>
								{buildingItem.name}
							</Button>
						</Box>
					))}

					<Box className="buildings-list-item">
						<Button
							size="small"
							variant="outlined"
							color="primary"
							startIcon={<AddIcon />}
							onClick={handleAddButtonClick}
							sx={{ textTransform: "capitalize" }}
							disabled={buildingsList.some((item) => item.name === NEW_BUILDING_NAME || item.new_building)}
						>
							Add New Wing
						</Button>
					</Box>
				</Box>

				<Box className="buildings-list-scroll-arrow right" onClick={() => handleScrollBuildingList("right")}>
					<ArrowRightIcon />
				</Box>
			</Box>

			<Box className="building-inputs-wrapper">
				<Grid container spacing={2}>
					<Grid item xs={12} md={3}>
						<InputLabel
							htmlFor="wing-name-input"
							sx={{ fontWeight: 500, color: "var(--color-primary-dark)", marginBottom: "0.5rem" }}
						>
							Wing Name
						</InputLabel>

						<TextField
							fullWidth
							size="small"
							variant="filled"
							id="wing-name-input"
							placeholder="Add wing name"
							value={selectedBuildingInputs.name}
							onChange={(event) => handleChangeSelectedBuildingInput(event.target.value, "name")}
							error={!!buildingInputErrors.name}
							helperText={buildingInputErrors.name}
							InputProps={{ disableUnderline: true, hiddenLabel: true, classes: { root: "building-input-root" } }}
							disabled={IsInputDisabled}
							onFocus={(event) => {
								if (event.target.value === NEW_BUILDING_NAME) {
									event.target.select();
								}
							}}
						/>
					</Grid>

					<Grid item xs={12} md={3}>
						<InputLabel
							htmlFor="delimiter-input"
							sx={{ fontWeight: 500, color: "var(--color-primary-dark)", marginBottom: "0.5rem" }}
						>
							Delimiter
						</InputLabel>

						<FormControl
							fullWidth
							size="small"
							variant="filled"
							error={!!buildingInputErrors.delimiter}
							hiddenLabel
							id="delimiter-input"
							disabled={IsInputDisabled}
						>
							<Select
								fullWidth
								placeholder="Select Delimiter"
								value={selectedBuildingInputs.delimiter}
								onChange={(event) => handleChangeSelectedBuildingInput(event.target.value, "delimiter")}
								disableUnderline
								classes={{ root: "building-input-root" }}
							>
								<MenuItem value="-">-</MenuItem>
								<MenuItem value="/">/</MenuItem>
							</Select>

							{buildingInputErrors.delimiter ? <FormHelperText>{buildingInputErrors.delimiter}</FormHelperText> : null}
						</FormControl>
					</Grid>

					<Grid item xs={12} md={3}>
						<InputLabel
							htmlFor="floors-input"
							sx={{ fontWeight: 500, color: "var(--color-primary-dark)", marginBottom: "0.5rem" }}
						>
							Floors
						</InputLabel>

						<NumberInput
							fullWidth
							positiveOnly
							disableSigned
							disableDecimal
							size="small"
							variant="filled"
							id="floors-input"
							placeholder="Add number of floors in building"
							value={selectedBuildingInputs.floors}
							onChange={(event) => handleChangeSelectedBuildingInput(event.target.value, "floors")}
							error={!!buildingInputErrors.floors}
							helperText={buildingInputErrors.floors}
							InputProps={{ disableUnderline: true, hiddenLabel: true, classes: { root: "building-input-root" } }}
							disabled={IsInputDisabled || !selectedBuildingInputs.showFloorNumber}
						/>

						<FormControlLabel
							label="Show Floor Number"
							control={
								<Checkbox
									checked={selectedBuildingInputs.showFloorNumber}
									onChange={(_, checked) => handleChangeShowFlatNumberInput(checked)}
								/>
							}
						/>
					</Grid>

					<Grid item xs={12} md={3}>
						<InputLabel
							htmlFor="flats-per-floor-input"
							sx={{ fontWeight: 500, color: "var(--color-primary-dark)", marginBottom: "0.5rem" }}
						>
							Flats Per Floor
						</InputLabel>

						<NumberInput
							fullWidth
							positiveOnly
							disableSigned
							disableDecimal
							size="small"
							variant="filled"
							id="flats-per-floor-input"
							placeholder="Add number of flats per floor"
							value={selectedBuildingInputs.flats}
							onChange={(event) => handleChangeSelectedBuildingInput(event.target.value, "flats")}
							error={!!buildingInputErrors.flats}
							helperText={buildingInputErrors.flats}
							InputProps={{ disableUnderline: true, hiddenLabel: true, classes: { root: "building-input-root" } }}
							disabled={IsInputDisabled}
						/>
					</Grid>
				</Grid>
			</Box>

			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
				<Typography variant="h6" color="var(--color-primary-dark)">
					Preview of Wing
				</Typography>

				<LoadingButton
					disableElevation
					variant="contained"
					color="success"
					startIcon={<SaveIcon />}
					sx={{ paddingX: 3 }}
					onClick={handleSaveButtonClick}
					disabled={IsSaveButtonDisabled}
					loading={saveButtonLoading}
					loadingPosition="start"
				>
					Save
				</LoadingButton>
			</Box>

			<Box className="building-config-actions-wrapper">
				<Button
					variant="outlined"
					color="success"
					size="small"
					startIcon={<AddCircleIcon />}
					onClick={handleOpenAddFlatDialog}
					// disabled={flatSelectionEnabled}
					disabled={IsInputDisabled}
				>
					Add Flat
				</Button>

				{/* {flatSelectionEnabled ? (
					<Button
						variant="outlined"
						color="primary"
						size="small"
						startIcon={<CloseIcon />}
						onClick={handleDisableFlatSelection}
						sx={{ minWidth: "100px" }}
					>
						Cancel
					</Button>
				) : (
					<Button
						variant="outlined"
						color="primary"
						size="small"
						startIcon={<CheckBoxOutlineBlankIcon />}
						onClick={handleEnableFlatSelection}
						sx={{ minWidth: "100px" }}
					>
						Select
					</Button>
				)} */}

				{/* {flatSelectionEnabled ? (
					<Button
						variant="outlined"
						color="error"
						size="small"
						startIcon={<DeleteOutlineIcon />}
						disabled={selectedEntriesIndex.length <= 0}
						onClick={handleRemoveButtonClick}
					>
						Remove
					</Button>
				) : null} */}
			</Box>

			<Box className="buildings-flats-preview-wrapper">
				<Box className={`buildings-flats-preview ${smallWidth ? "small-width" : ""}`}>
					{selectedBuildingInputs.entries.map((item, index) => (
						<Box className="building-flats-preview-item" key={index}>
							{/* {flatSelectionEnabled ? (
								<Box className="icon-wrapper" onClick={() => handleToggleEntrySelection(index)}>
									{selectedEntriesIndex.includes(index) ? (
										<CheckBoxIcon fontSize="inherit" />
									) : (
										<CheckBoxOutlineBlankIcon fontSize="inherit" />
									)}
								</Box>
							) : null} */}

							<Typography color="inherit" variant="body2" textAlign="center">
								{item}
							</Typography>
						</Box>
					))}
				</Box>
			</Box>

			<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "1rem" }}>
				{/* {buildingsList.length > 1 ? (
					<Button
						variant="outlined"
						color="error"
						startIcon={<DeleteOutlineIcon />}
						onClick={handleOpenDeleteConfirmationDialog}
					>
						Delete Wing
					</Button>
				) : null} */}

				{/* <LoadingButton
					disableElevation
					variant="contained"
					color="success"
					startIcon={<SaveIcon />}
					sx={{ paddingX: 3 }}
					onClick={handleSaveButtonClick}
					disabled={isSaveButtonDisabled()}
					loading={saveButtonLoading}
					loadingPosition="start"
				>
					Save
				</LoadingButton> */}
			</Box>

			{/* <ConfirmationDialog
				open={showDeleteConfirmationDialog}
				title="Delete Building"
				heading="Are you sure you want to delete this building?"
				onClose={handleCloseDeleteConfirmationDialog}
				onConfirm={handleSubmitDeleteBuilding}
				width="sm"
				color="error"
			/> */}

			<AddFlatDialog open={showAddFlatDialog} onClose={handleCloseAddFlatDialog} onSubmit={handleAddFlatSubmit} />
		</Box>
	);
};

export default BuildingConfigurationComponent;
