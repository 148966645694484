import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	InputLabel,
	TextField,
	Typography
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import moment, { Moment } from "moment";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useRenewSubscriptionMutation } from "../../redux/reducers/customers.reducer";
import { useAppDispatch, useAppSelector } from "../../redux";
import { LoadingButton } from "@mui/lab";
import { IRenewSubscriptionRequestData } from "../../types";
import { showFeedbackNotification } from "../../redux/reducers/feedbackNotification.reducer";

interface IRenewSubscriptionDialogProps {
	open: boolean;
	society_id: number;
	society_name: string;
	onClose: (success?: boolean) => void;
}

interface IRenewSubscriptionFormFields {
	new_invoice_number: string;
	start_date: Moment;
	end_date: Moment;
}

const RenewSubscriptionDialog: FC<IRenewSubscriptionDialogProps> = (props) => {
	const { open, society_id, society_name, onClose } = props;

	const dispatch = useAppDispatch();

	const authState = useAppSelector((state) => state.auth);

	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [passwordInput, setPasswordInput] = useState<string>("");

	// APIS
	const [
		renewSubscription,
		{
			isLoading: renewSubscriptionLoading,
			isSuccess: renewSubscriptionSuccess,
			error: renewSubscriptionError,
			reset: resetRenewSubscriptionState
		}
	] = useRenewSubscriptionMutation();

	const {
		reset,
		watch,
		control,
		trigger,
		setValue,
		register,
		formState: { errors }
	} = useForm<IRenewSubscriptionFormFields>();

	const newInvoiceNumber = watch("new_invoice_number");
	const startDate = watch("start_date");
	const endDate = watch("end_date");

	function handleChangePasswordInput(event: ChangeEvent<HTMLInputElement>) {
		setPasswordInput(event.target.value);
	}

	function handleSetYearsButtonClick(years: number) {
		setValue("start_date", moment().startOf("day"));
		setValue("end_date", moment().add(years, "years").subtract(1, "day").endOf("day"));
	}

	function isYearButtonActive(years: number): boolean {
		if (startDate && endDate && startDate.isSame(moment(), "day")) {
			const endYearDate = moment().add(years, "years").subtract(1, "day");

			if (endDate.isSame(endYearDate, "day")) {
				return true;
			}
		}

		return false;
	}

	function handleProceedButtonClick() {
		if (!showConfirmation) {
			trigger().then((isFormValid) => {
				if (isFormValid) {
					setShowConfirmation(true);
				}
			});
		} else {
			if (society_id >= 0 && startDate && endDate && passwordInput) {
				const data: IRenewSubscriptionRequestData = {
					id: society_id,
					data: {
						subscription_start_date: startDate.format("YYYY-MM-DD HH:mm:ss"),
						subscription_expiry_date: endDate.format("YYYY-MM-DD HH:mm:ss"),
						society: society_id,
						username: authState.user.username,
						password: passwordInput,
						invoice_no: newInvoiceNumber
					}
				};

				renewSubscription(data);
			}
		}
	}

	useEffect(() => {
		if (open) {
			reset({
				new_invoice_number: "",
				start_date: moment().startOf("day"),
				end_date: moment().add(6, "months").subtract(1, "day").endOf("day")
			});
			setShowConfirmation(false);
			resetRenewSubscriptionState();
			setPasswordInput("");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	useEffect(() => {
		if (renewSubscriptionSuccess) {
			onClose(true);
		}
	}, [onClose, renewSubscriptionSuccess]);

	useEffect(() => {
		if (renewSubscriptionError) {
			dispatch(
				showFeedbackNotification({
					message:
						"data" in renewSubscriptionError && renewSubscriptionError.data
							? String(renewSubscriptionError.data)
							: "Failed to renew subsctiption",
					severity: "error"
				})
			);

			resetRenewSubscriptionState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [renewSubscriptionError]);

	return (
		<Dialog open={open} fullWidth maxWidth="lg">
			<DialogTitle textAlign="center" classes={{ root: "dialog-title-root primary" }}>
				Renew Subscription
				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={() => onClose()}>
						<CloseIcon color="inherit" />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				{showConfirmation ? (
					<Box
						component="form"
						noValidate
						onSubmit={(event) => {
							event.preventDefault();
							handleProceedButtonClick();
						}}
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
							gap: "1rem"
						}}
					>
						<Typography variant="h6" textAlign="center">
							Are you sure you want to renew the subscription?
						</Typography>

						<Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
							<InfoIcon fontSize="small" />
							<Typography textAlign="center">
								{`Your are about to renew Gate-Guard Subscription for "`}
								<strong>{society_name}</strong>
								{`" from `}
								<strong>{startDate ? startDate.format("DD/MM/YYYY") : ""}</strong>
								{` to `}
								<strong>{endDate ? endDate.format("DD/MM/YYYY") : ""}</strong>
								{"."}
							</Typography>
						</Box>

						<Typography textAlign="center">
							{`This subscription will be linked to invoice number `}
							<strong>{newInvoiceNumber ?? ""}</strong>
							{`. Please enter your password to proceed.`}
						</Typography>

						<TextField
							autoFocus={!navigator.userAgent.match(/Android|webOS|iPhone|iPod|Blackberry/i)}
							variant="standard"
							color="info"
							placeholder="Enter password here"
							inputProps={{ style: { textAlign: "center" } }}
							autoComplete="off"
							type="password"
							sx={{ width: "300px" }}
							value={passwordInput}
							onChange={handleChangePasswordInput}
						/>

						<Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
							<Button variant="outlined" color="error" onClick={() => setShowConfirmation(false)}>
								Cancel
							</Button>

							<LoadingButton
								disableElevation
								variant="contained"
								color="primary"
								type="submit"
								// onClick={handleProceedButtonClick}
								loading={renewSubscriptionLoading}
							>
								Proceed
							</LoadingButton>
						</Box>
					</Box>
				) : (
					<Grid container spacing={2}>
						<Grid item xs={12} md={4}>
							<InputLabel
								required
								htmlFor="new-invoice-number-input"
								error={!!errors.new_invoice_number}
								classes={{ root: "customer-details-form-input-label-root" }}
							>
								New Invoice Number
							</InputLabel>

							<TextField
								required
								fullWidth
								size="small"
								variant="filled"
								id="new-invoice-number-input"
								placeholder="Add new invoice number"
								error={!!errors.new_invoice_number}
								helperText={
									errors.new_invoice_number && errors.new_invoice_number.message
										? errors.new_invoice_number.message.toString()
										: ""
								}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
								{...register("new_invoice_number", { required: "New invoice number is required" })}
							/>
						</Grid>

						<Grid item xs={12} md={8}>
							<Grid container columnSpacing={2}>
								<Grid item xs={12}>
									<InputLabel classes={{ root: "customer-details-form-input-label-root" }}>
										Subscription Period
									</InputLabel>
								</Grid>

								<Grid item xs={12} md={4}>
									<Controller
										control={control}
										name="start_date"
										rules={{
											required: "Start date is required"
										}}
										render={({ field: { value, onChange } }) => (
											<DatePicker
												label="From"
												format="DD/MM/YYYY"
												value={value}
												onChange={onChange}
												minDate={moment()}
												slotProps={{
													textField: {
														variant: "outlined",
														color: "primary",
														size: "small",
														error: !!errors.start_date,
														helperText:
															errors.start_date && errors.start_date.message ? errors.start_date.message.toString() : ""
													}
												}}
											/>
										)}
									/>
								</Grid>

								<Grid item xs={12} md={4}>
									<Controller
										control={control}
										name="end_date"
										rules={{
											required: "End date is required",
											validate: (value) => {
												if (value.isBefore(startDate)) {
													return "End date should be after start date";
												}

												return true;
											}
										}}
										render={({ field: { value, onChange } }) => (
											<DatePicker
												label="To"
												format="DD/MM/YYYY"
												value={value}
												onChange={onChange}
												minDate={startDate}
												slotProps={{
													textField: {
														variant: "outlined",
														color: "primary",
														size: "small",
														error: !!errors.end_date,
														helperText:
															errors.end_date && errors.end_date.message ? errors.end_date.message.toString() : ""
													}
												}}
											/>
										)}
									/>
								</Grid>

								<Grid item xs={12}>
									<Box sx={{ marginTop: "1rem", display: "flex", alignItems: "center", gap: "0.5rem" }}>
										<Button
											disableElevation
											variant={isYearButtonActive(1) ? "contained" : "outlined"}
											color="info"
											size="small"
											onClick={() => handleSetYearsButtonClick(1)}
										>
											1 Year
										</Button>

										<Button
											disableElevation
											variant={isYearButtonActive(2) ? "contained" : "outlined"}
											color="info"
											size="small"
											onClick={() => handleSetYearsButtonClick(2)}
										>
											2 Years
										</Button>

										<Button
											disableElevation
											variant={isYearButtonActive(3) ? "contained" : "outlined"}
											color="info"
											size="small"
											onClick={() => handleSetYearsButtonClick(3)}
										>
											3 Years
										</Button>
									</Box>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12}>
							<Box sx={{ display: "flex", justifyContent: "space-between" }}>
								<Button variant="outlined" color="error" onClick={() => onClose()}>
									Cancel
								</Button>

								<Button
									disableElevation
									variant="contained"
									color="primary"
									endIcon={<ChevronRightIcon />}
									onClick={handleProceedButtonClick}
								>
									Proceed
								</Button>
							</Box>
						</Grid>
					</Grid>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default RenewSubscriptionDialog;
