import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	IconButton,
	InputLabel,
	Radio,
	RadioGroup,
	TextField,
	Typography
} from "@mui/material";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import PaidIcon from "@mui/icons-material/Paid";
import ContactsIcon from "@mui/icons-material/Contacts";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import ApartmentIcon from "@mui/icons-material/Apartment";
import EmailIcon from "@mui/icons-material/Email";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";

import { ReactComponent as InactiveCustomerIcon } from "../../assets/icons/inactive-customer.svg";
import { ICustomerRowDetails } from ".";
import { CustomerStatusType, ICustomerContactDetails, ISocietyConfigDetails } from "../../types";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { ROWS_PER_PAGE } from "../../utils/constants";
import CustomerContactDetailsDialog from "./AddCustomerDialog/CustomerContactDetailsDialog";
import { ISocietyContactDetails } from "./AddCustomerDialog/ContactDetails";
import { useEditSocietyConfigMutation } from "../../redux/reducers/settings.reducer";
import { LoadingButton } from "@mui/lab";
import RenewSubscriptionDialog from "./RenewSubscriptionDialog";
import { useDisableSubscriptionMutation } from "../../redux/reducers/customers.reducer";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { useAppDispatch, useAppSelector } from "../../redux";
import SocietyConfiguration from "../../components/SocietyConfiguration";
import { showFeedbackNotification } from "../../redux/reducers/feedbackNotification.reducer";
import ReportingTab from "./ReportingTab";
import AdvancedTab from "./AdvancedTab";
import CustomFieldsTab from "./CustomFieldsTab";

interface ICustomerDetailsDialogProps {
	customerDetails: ICustomerRowDetails | null;
	onClose: () => void;
}

enum CUSTOMER_DETAILS_DIALOG_TAB_E {
	SUBSCRIPTION,
	CONTACT,
	SOCIETY_CONFIG,
	REPORTING,
	CUSTOM_FIELDS,
	ADVANCED
}

type CustomerDetailsTabContentRendererType = {
	[key in CUSTOMER_DETAILS_DIALOG_TAB_E]: ReactNode;
};

interface ICustomerFormFields {
	name: string;
	subscription_remaining: string;
	subscription_status: CustomerStatusType;
	previous_invoice_number: string;
	system_integrator: string;
	is_active: boolean;
}

const CustomerDetailsDialog: FC<ICustomerDetailsDialogProps> = (props) => {
	const { customerDetails, onClose } = props;
	const open = !!customerDetails;

	const dispatch = useAppDispatch();

	const authState = useAppSelector((state) => state.auth);

	const [selectedTab, setSelectedTab] = useState<CUSTOMER_DETAILS_DIALOG_TAB_E>(
		CUSTOMER_DETAILS_DIALOG_TAB_E.SUBSCRIPTION
	);

	const [customerContactDetails, setCustomerContactDetails] = useState<ICustomerContactDetails[]>([]);
	const [showContactDetailsDialog, setShowContactDetailsDialog] = useState<boolean>(false);
	const [selectedContactDetails, setSelectedContactDetails] = useState<ISocietyContactDetails | null>(null);

	const [showRenewSubscriptionDialog, setShowRenewSubscriptionDialog] = useState<boolean>(false);
	const [showDisableSubscriptionConfirmation, setShowDisableSubscriptionConfirmation] = useState<boolean>(false);

	const [societyDetails, setSocietyDetails] = useState<ISocietyConfigDetails | null>(null);

	// APIS
	const [
		editSocietyConfig,
		{
			isLoading: editSocietyConfigLoading,
			isSuccess: editSocietyConfigSuccess,
			error: editSocietyConfigError,
			reset: resetEditSocietyConfigState
		}
	] = useEditSocietyConfigMutation();

	const [
		disableSubscription,
		{
			isLoading: disableSubscriptionLoading,
			isSuccess: disableSubscriptionSuccess,
			error: disableSubscriptionError,
			reset: resetDisableSubscriptionState
		}
	] = useDisableSubscriptionMutation();

	const {
		reset,
		watch,
		register,
		formState: { errors }
	} = useForm<ICustomerFormFields>();

	const subscriptionStatus = watch("subscription_status");
	const customerActive = watch("is_active");

	function handleChangeSelectedTab(updatedTab: CUSTOMER_DETAILS_DIALOG_TAB_E) {
		setSelectedTab(updatedTab);
	}

	function handleOpenContactDetailsDialog(contactDetails: ICustomerContactDetails | null) {
		if (contactDetails) {
			setSelectedContactDetails({
				id: contactDetails.id,
				first_name: contactDetails.first_name,
				last_name: contactDetails.last_name,
				contact_number: contactDetails.contact_number,
				designation: contactDetails.designation,
				email: contactDetails.email_address,
				is_subscribed: contactDetails.is_subscribed
			});
		} else {
			setSelectedContactDetails(null);
		}
		setShowContactDetailsDialog(true);
	}

	function handleCloseContactDetailsDialog() {
		setShowContactDetailsDialog(false);
		setSelectedContactDetails(null);
	}

	function handleSubmitContactDetails(contactDetails: ISocietyContactDetails) {
		setCustomerContactDetails((currentCustomerContactDetails) => {
			const updatedCustomerContactDetails = [...currentCustomerContactDetails];

			if (contactDetails.id < 0) {
				updatedCustomerContactDetails.push({
					id: updatedCustomerContactDetails.length,
					first_name: contactDetails.first_name,
					last_name: contactDetails.last_name,
					contact_number: contactDetails.contact_number,
					designation: contactDetails.designation,
					email_address: contactDetails.email,
					is_subscribed: contactDetails.is_subscribed
				});
			} else {
				const index = updatedCustomerContactDetails.findIndex((item) => item.id === contactDetails.id);

				if (index >= 0) {
					updatedCustomerContactDetails[index] = {
						id: contactDetails.id,
						first_name: contactDetails.first_name,
						last_name: contactDetails.last_name,
						contact_number: contactDetails.contact_number,
						designation: contactDetails.designation,
						email_address: contactDetails.email,
						is_subscribed: contactDetails.is_subscribed
					};
				}
			}

			return updatedCustomerContactDetails;
		});

		handleCloseContactDetailsDialog();
	}

	function handleDeleteContact(id: number) {
		setCustomerContactDetails((currentCustomerContactDetails) => {
			const updatedCustomerContactDetails = currentCustomerContactDetails.filter((item) => item.id !== id);
			return updatedCustomerContactDetails;
		});

		handleCloseContactDetailsDialog();
	}

	function handleSaveButtonClick() {
		if (customerDetails) {
			editSocietyConfig({
				id: customerDetails.id,
				data: {
					contact_details: customerContactDetails.map((item) => ({
						first_name: item.first_name,
						last_name: item.last_name,
						contact_number: item.contact_number,
						designation: item.designation,
						email_address: item.email_address,
						is_subscribed: item.is_subscribed
					}))
				}
			});
		}
	}

	function handleOpenRenewSubscriptionDialog() {
		setShowRenewSubscriptionDialog(true);
	}

	function handleCloseRenewSubscriptionDialog(success?: boolean) {
		setShowRenewSubscriptionDialog(false);
		if (success) {
			onClose();
		}
	}

	function handleOpenDisableSubscriptionConfirmationDialog() {
		setShowDisableSubscriptionConfirmation(true);
	}

	function handleCloseDisableSubscriptionConfirmationDialog() {
		setShowDisableSubscriptionConfirmation(false);
	}

	function handleChangeCustomerStatus(updatedValue: "active" | "inactive") {
		if (updatedValue === "active") {
		} else if (updatedValue === "inactive") {
			handleOpenDisableSubscriptionConfirmationDialog();
		}
	}

	function handleConfirmDisableSubscription(password?: string) {
		if (customerDetails) {
			disableSubscription({
				id: customerDetails.id,
				data: {
					username: authState.user.username,
					society: customerDetails.id,
					password: password ?? ""
				}
			});
		}
	}

	function updadateSocietyDetails(updatedSocietyDetails: ISocietyConfigDetails) {
		setSocietyDetails(updatedSocietyDetails);
	}

	useEffect(() => {
		if (customerDetails) {
			reset({
				name: customerDetails.customer_name,
				subscription_remaining: `${customerDetails.days_left} Day(s)`,
				subscription_status: customerDetails.status,
				previous_invoice_number: customerDetails.previous_invoice_number,
				system_integrator: customerDetails.system_integrator,
				is_active: customerDetails.status !== "Inactive"
			});

			setCustomerContactDetails(
				customerDetails.contact_details.map((item, index) => ({
					...item,
					id: index
				}))
			);

			setSocietyDetails({
				id: customerDetails.id,
				society_uuid: "",
				name: customerDetails.customer_name,
				city: "",
				state: "",
				country: "",
				landmark: "",
				area: "",
				pincode: 0,
				deployment_stage: 0,
				comment: null,
				building_config: customerDetails.building_config,
				is_synced: false,
				contact_details: customerDetails.contact_details,
				system_integrator: 0,
				invoice_no: customerDetails.previous_invoice_number,
				address_line1: "",
				address_line2: "",
				enable_secure_login: false,
				custom_fields: [],
				tat_config: [],
				mailing_list: []
			});

			setSelectedTab(CUSTOMER_DETAILS_DIALOG_TAB_E.SUBSCRIPTION);
		}
	}, [customerDetails, reset]);

	useEffect(() => {
		if (editSocietyConfigSuccess) {
			onClose();
			resetEditSocietyConfigState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editSocietyConfigSuccess, onClose]);

	useEffect(() => {
		if (disableSubscriptionSuccess) {
			resetDisableSubscriptionState();
			handleCloseDisableSubscriptionConfirmationDialog();
			onClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [disableSubscriptionSuccess]);

	useEffect(() => {
		if (disableSubscriptionError) {
			dispatch(
				showFeedbackNotification({
					message:
						"data" in disableSubscriptionError && disableSubscriptionError.data
							? String(disableSubscriptionError.data)
							: "Something went wrong",
					severity: "error"
				})
			);

			resetDisableSubscriptionState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [disableSubscriptionError]);

	useEffect(() => {
		if (editSocietyConfigError) {
			dispatch(
				showFeedbackNotification({
					message:
						"data" in editSocietyConfigError && editSocietyConfigError.data
							? String(editSocietyConfigError.data)
							: "Something went wrong",
					severity: "error"
				})
			);

			resetEditSocietyConfigState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editSocietyConfigError]);

	const columnsData: GridColDef[] = [
		{
			field: "name",
			headerName: "Name",
			flex: 1,
			minWidth: 150,
			sortable: false,
			valueGetter: (params: GridValueGetterParams<ICustomerContactDetails>) =>
				`${params.row.first_name} ${params.row.last_name}`.trim()
		},
		{
			field: "designation",
			headerName: "Designation",
			flex: 1,
			minWidth: 120,
			sortable: false
		},
		{
			field: "contact_number",
			headerName: "Contact Number",
			flex: 1,
			minWidth: 150,
			sortable: false
		},
		{
			field: "actions",
			headerName: "",
			width: 50,
			sortable: false,
			renderCell: (params: GridRenderCellParams<ICustomerContactDetails>) => (
				<Box sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
					<IconButton size="small" onClick={() => handleOpenContactDetailsDialog(params.row)}>
						<EditIcon fontSize="small" />
					</IconButton>
				</Box>
			)
		}
	];

	const TabContentRenderer: CustomerDetailsTabContentRendererType = {
		[CUSTOMER_DETAILS_DIALOG_TAB_E.SUBSCRIPTION]: (
			<Grid container spacing={2}>
				<Grid item xs={12} md={4}>
					<InputLabel
						htmlFor="name-input"
						error={!!errors.name}
						classes={{ root: "customer-details-form-input-label-root" }}
					>
						Customer
					</InputLabel>

					<TextField
						fullWidth
						size="small"
						variant="filled"
						id="name-input"
						placeholder="Add customer name"
						error={!!errors.name}
						helperText={errors.name && errors.name.message ? errors.name.message.toString() : ""}
						InputProps={{
							readOnly: true,
							hiddenLabel: true,
							disableUnderline: true,
							classes: { root: "filled-input-root" }
						}}
						{...register("name")}
					/>
				</Grid>

				<Grid item xs={12} md={4}>
					<InputLabel
						htmlFor="subscription-remaining-input"
						error={!!errors.subscription_remaining}
						classes={{ root: "customer-details-form-input-label-root" }}
					>
						Subscription Remaining
					</InputLabel>

					<TextField
						fullWidth
						size="small"
						variant="filled"
						id="subscription-remaining-input"
						error={!!errors.subscription_remaining}
						helperText={
							errors.subscription_remaining && errors.subscription_remaining.message
								? errors.subscription_remaining.message.toString()
								: ""
						}
						InputProps={{
							readOnly: true,
							hiddenLabel: true,
							disableUnderline: true,
							classes: { root: "filled-input-root" }
						}}
						{...register("subscription_remaining")}
					/>
				</Grid>

				<Grid item xs={12} md={4} sx={{ display: "flex", flexDirection: "column" }}>
					<InputLabel htmlFor="subscription-status-input" classes={{ root: "customer-details-form-input-label-root" }}>
						Subscription Status
					</InputLabel>

					<Box className={`customer-subscription-status-input ${subscriptionStatus}`}>
						<Box className="icon-wrapper">
							{subscriptionStatus === "Active" ? (
								<FactCheckIcon width={24} />
							) : subscriptionStatus === "Renewal" ? (
								<TimelapseIcon width={24} />
							) : subscriptionStatus === "Inactive" ? (
								<InactiveCustomerIcon width={24} />
							) : null}
						</Box>

						<Typography>{subscriptionStatus}</Typography>
					</Box>
				</Grid>

				<Grid item xs={12} md={4}>
					<InputLabel
						htmlFor="previous-invoice-number-input"
						error={!!errors.previous_invoice_number}
						classes={{ root: "customer-details-form-input-label-root" }}
					>
						Previous Invoice Number
					</InputLabel>

					<TextField
						fullWidth
						size="small"
						variant="filled"
						id="previous-invoice-number-input"
						placeholder="Add previous invoice number"
						error={!!errors.previous_invoice_number}
						helperText={
							errors.previous_invoice_number && errors.previous_invoice_number.message
								? errors.previous_invoice_number.message.toString()
								: ""
						}
						InputProps={{
							readOnly: true,
							hiddenLabel: true,
							disableUnderline: true,
							classes: { root: "filled-input-root" }
						}}
						{...register("previous_invoice_number")}
					/>
				</Grid>

				<Grid item xs={12} md={4}>
					<InputLabel
						htmlFor="system-integrator-input"
						error={!!errors.system_integrator}
						classes={{ root: "customer-details-form-input-label-root" }}
					>
						System Integrator
					</InputLabel>

					<TextField
						fullWidth
						size="small"
						variant="filled"
						id="system-integrator-input"
						error={!!errors.system_integrator}
						helperText={
							errors.system_integrator && errors.system_integrator.message
								? errors.system_integrator.message.toString()
								: ""
						}
						InputProps={{
							readOnly: true,
							hiddenLabel: true,
							disableUnderline: true,
							classes: { root: "filled-input-root" }
						}}
						{...register("system_integrator")}
					/>
				</Grid>

				<Grid item xs={12}>
					<Box className="customer-details-dialog-actions-wrapper">
						<RadioGroup
							row
							sx={{ gap: "2rem" }}
							onChange={(_event, value) => handleChangeCustomerStatus(value as "active" | "inactive")}
						>
							<FormControlLabel
								value="active"
								control={<Radio color="error" />}
								label="Active"
								checked={customerActive}
							/>

							<FormControlLabel
								value="inactive"
								control={<Radio color="error" />}
								label="Inactive"
								checked={!customerActive}
							/>
						</RadioGroup>

						<Button
							disableElevation
							variant="contained"
							color="success"
							endIcon={<ChevronRightIcon />}
							onClick={handleOpenRenewSubscriptionDialog}
						>
							Renew Subscription
						</Button>
					</Box>
				</Grid>
			</Grid>
		),
		[CUSTOMER_DETAILS_DIALOG_TAB_E.CONTACT]: (
			<Box className="customer-details-contacts-tab-content-wrapper">
				<Box className="customer-details-contacts-header-wrapper">
					<Typography fontWeight={500}>Contact Information</Typography>

					<Button
						size="small"
						variant="outlined"
						color="success"
						startIcon={<AddCircleIcon />}
						sx={{ paddingX: 3 }}
						onClick={() => handleOpenContactDetailsDialog(null)}
					>
						Add
					</Button>
				</Box>

				<Box>
					<DataGrid
						columns={columnsData}
						rows={customerContactDetails}
						disableRowSelectionOnClick
						disableColumnMenu
						rowSpacingType="border"
						density="compact"
						columnHeaderHeight={80}
						pageSizeOptions={[ROWS_PER_PAGE]}
						getRowId={(row) => row.contact_number}
						// slots={{ pagination: CustomersListPagination }}
						// hideFooter={(getCustomersResponse?.count ?? 0) <= ROWS_PER_PAGE}
						// slotProps={{ footer: { sx: { justifyContent: "center" } } }}
						// paginationMode="server"
						// paginationModel={{ page: filters.page - 1, pageSize: ROWS_PER_PAGE }}
						// onPaginationModelChange={handlePageChange}
						// rowCount={getCustomersResponse?.count ?? 0}
						// loading={getCustomersLoading || getCustomersFetching}
						classes={{
							columnSeparator: "table-column-separator",
							columnHeader: `table-column-header`,
							root: "table-root",
							virtualScroller: "table-virtual-scroller"
						}}
					/>
				</Box>

				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					<LoadingButton
						disableElevation
						variant="contained"
						color="success"
						sx={{ paddingX: 3 }}
						onClick={handleSaveButtonClick}
						loading={editSocietyConfigLoading}
					>
						Save
					</LoadingButton>
				</Box>
			</Box>
		),
		[CUSTOMER_DETAILS_DIALOG_TAB_E.SOCIETY_CONFIG]: (
			<SocietyConfiguration
				showEditActions
				societyDetails={societyDetails}
				onSubmit={onClose}
				onCancel={onClose}
				onChangeSocietyDetails={updadateSocietyDetails}
			/>
		),
		[CUSTOMER_DETAILS_DIALOG_TAB_E.REPORTING]: <ReportingTab customerId={customerDetails?.id ?? -1} />,
		[CUSTOMER_DETAILS_DIALOG_TAB_E.CUSTOM_FIELDS]: <CustomFieldsTab customerId={customerDetails?.id ?? -1} />,
		[CUSTOMER_DETAILS_DIALOG_TAB_E.ADVANCED]: <AdvancedTab customerId={customerDetails?.id ?? -1} />
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
			<DialogTitle textAlign="center" classes={{ root: "dialog-title-root primary" }}>
				Customer Details
				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				<Box className="customer-details-dialog-content-wrapper">
					<Box className="customer-details-tabs-wrapper">
						<Button
							disableElevation
							variant={selectedTab === CUSTOMER_DETAILS_DIALOG_TAB_E.SUBSCRIPTION ? "contained" : "outlined"}
							color="primary"
							startIcon={<PaidIcon />}
							onClick={() => handleChangeSelectedTab(CUSTOMER_DETAILS_DIALOG_TAB_E.SUBSCRIPTION)}
							sx={{ flexShrink: 0 }}
						>
							Subscription
						</Button>

						<Button
							disableElevation
							variant={selectedTab === CUSTOMER_DETAILS_DIALOG_TAB_E.CONTACT ? "contained" : "outlined"}
							color="primary"
							startIcon={<ContactsIcon />}
							onClick={() => handleChangeSelectedTab(CUSTOMER_DETAILS_DIALOG_TAB_E.CONTACT)}
							sx={{ flexShrink: 0 }}
						>
							Contact
						</Button>

						<Button
							disableElevation
							variant={selectedTab === CUSTOMER_DETAILS_DIALOG_TAB_E.SOCIETY_CONFIG ? "contained" : "outlined"}
							color="primary"
							startIcon={<ApartmentIcon />}
							onClick={() => handleChangeSelectedTab(CUSTOMER_DETAILS_DIALOG_TAB_E.SOCIETY_CONFIG)}
							sx={{ flexShrink: 0 }}
						>
							Society Configuration
						</Button>

						<Button
							disableElevation
							variant={selectedTab === CUSTOMER_DETAILS_DIALOG_TAB_E.REPORTING ? "contained" : "outlined"}
							color="primary"
							startIcon={<EmailIcon />}
							onClick={() => handleChangeSelectedTab(CUSTOMER_DETAILS_DIALOG_TAB_E.REPORTING)}
							sx={{ flexShrink: 0 }}
						>
							Reporting
						</Button>

						<Button
							disableElevation
							variant={selectedTab === CUSTOMER_DETAILS_DIALOG_TAB_E.CUSTOM_FIELDS ? "contained" : "outlined"}
							color="primary"
							startIcon={<DashboardCustomizeIcon />}
							onClick={() => handleChangeSelectedTab(CUSTOMER_DETAILS_DIALOG_TAB_E.CUSTOM_FIELDS)}
							sx={{ flexShrink: 0 }}
						>
							Custom Fields
						</Button>

						<Button
							disableElevation
							variant={selectedTab === CUSTOMER_DETAILS_DIALOG_TAB_E.ADVANCED ? "contained" : "outlined"}
							color="primary"
							startIcon={<SettingsSuggestIcon />}
							onClick={() => handleChangeSelectedTab(CUSTOMER_DETAILS_DIALOG_TAB_E.ADVANCED)}
							sx={{ flexShrink: 0 }}
						>
							Advanced
						</Button>
					</Box>

					<Box>{TabContentRenderer[selectedTab]}</Box>
				</Box>
			</DialogContent>

			<CustomerContactDetailsDialog
				open={showContactDetailsDialog}
				contactDetails={selectedContactDetails}
				contactsList={customerContactDetails.map((item) => ({
					id: item.id,
					contact_number: item.contact_number,
					designation: item.designation,
					email: item.email_address,
					first_name: item.first_name,
					is_subscribed: item.is_subscribed,
					last_name: item.last_name
				}))}
				onClose={handleCloseContactDetailsDialog}
				onSubmit={handleSubmitContactDetails}
				onDelete={handleDeleteContact}
			/>

			<RenewSubscriptionDialog
				open={showRenewSubscriptionDialog}
				society_id={customerDetails?.id ?? -1}
				society_name={customerDetails?.customer_name ?? ""}
				onClose={handleCloseRenewSubscriptionDialog}
			/>

			<ConfirmationDialog
				open={showDisableSubscriptionConfirmation}
				title="Deactivate Subsctiption"
				heading="Are you sure you want to deactivate subscription?"
				warning={`You are about to deactivate Gate-Guard Subscription for ${customerDetails?.customer_name ?? ""}`}
				onClose={handleCloseDisableSubscriptionConfirmationDialog}
				onConfirm={handleConfirmDisableSubscription}
				color="error"
				width="md"
				confirmationType="password"
				confirmButtonLoading={disableSubscriptionLoading}
			/>
		</Dialog>
	);
};

export default CustomerDetailsDialog;
