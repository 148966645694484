import {
	Box,
	Button,
	Divider,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	Switch,
	TextField,
	Typography
} from "@mui/material";
import {
	DataGrid,
	GridColDef,
	GridPagination,
	GridPaginationModel,
	GridRenderCellParams,
	GridValueGetterParams
} from "@mui/x-data-grid";
import { isEqual, maxBy } from "lodash";
import { LoadingButton } from "@mui/lab";
import React, { ChangeEvent, FC, FormEvent, useEffect, useMemo, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";

import {
	useEditSocietyConfigMutation,
	useGetSocietyConfigQuery,
	useGetVehiclesListQuery,
	useUpdateVehicleMutation
} from "../../../redux/reducers/settings.reducer";
import { useAppDispatch } from "../../../redux";
import { ROWS_PER_PAGE } from "../../../utils/constants";
import NumberInput from "../../../components/NumberInput";
import AddVehicleAlertDialog from "./AddVehicleAlertDialog";
import TablePagination from "../../../components/TablePagination";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import { IGetVehiclesListRequestData, ISocietyConfigDetails } from "../../../types";
import AddToMailingListDialog, { IMailingInputDetails } from "./AddToMailingListDialog";
import { showFeedbackNotification } from "../../../redux/reducers/feedbackNotification.reducer";

interface IMailingListDetails {
	sr_no: number;
	first_name: string;
	last_name: string;
	email_address: string;
	contact_number: string;
	designation: string;
}

interface IOverstayAlertInputs {
	enabled: boolean;
	duration: string;
}

interface IVehicleAlertRowDetails {
	id: number;
	sr_no: number;
	vehicle_number: string;
}

interface IReportingTabProps {
	customerId: number;
}

const ReportingPagination = (props: any) => (
	<GridPagination
		{...props}
		ActionsComponent={TablePagination}
		labelDisplayedRows={() => <></>}
		classes={{ spacer: "grid-pagination-spacer-custom" }}
	/>
);

const ReportingTab: FC<IReportingTabProps> = (props) => {
	const { customerId } = props;

	const dispatch = useAppDispatch();

	const [showAddToMailingListDialog, setShowAddToMailingListDialog] = useState<boolean>(false);
	const [selectedMailingDetails, setSelectedMailingDetails] = useState<IMailingListDetails | null>(null);
	const [showAddVehicleAlertDialog, setShowAddVehicleAlertDialog] = useState<boolean>(false);
	const [vehicleAlertSearchInput, setVehicleAlertSearchInput] = useState<string>("");
	const [addToMailingListError, setAddToMailingListError] = useState<string>("");
	const [mailingListRowsData, setMailingListRowsData] = useState<IMailingListDetails[]>([]);
	const [selectedVehicleDetails, setSelectedVehicleDetails] = useState<IVehicleAlertRowDetails | null>(null);

	const [vehicleAlertFilters, setVehicleAlertFilters] = useState<IGetVehiclesListRequestData>({
		page: 1,
		send_alert: true,
		society: customerId
	});

	const [overstayAlertsInputs, setOverstayAlertsInputs] = useState<IOverstayAlertInputs>({
		enabled: false,
		duration: ""
	});

	// APIS
	// GET SOCIETY CONFIG
	const {
		data: getSocietyConfigResponse,
		isLoading: getSocietyConfigLoading,
		isFetching: getSocietyConfigFetching,
		refetch: refetchSocietyConfig
	} = useGetSocietyConfigQuery(customerId, { skip: customerId < 0 });

	// EDIT SOCIETY CONFIG
	const [
		editSocietyConfig,
		{
			isLoading: editSocietyConfigLoading,
			error: editSocietyConfigError,
			isSuccess: editSocietyConfigSuccess,
			reset: resetEditSocietyConfigState
		}
	] = useEditSocietyConfigMutation();

	// GET VEHICLES LIST
	const {
		data: getVehiclesListResponse,
		isLoading: getVehiclesListLoading,
		isFetching: getVehiclesListFetching,
		refetch: refetchVehiclesList
	} = useGetVehiclesListQuery(vehicleAlertFilters, { skip: vehicleAlertFilters.society < 0 });

	// UPDATE VEHICLE
	const [
		updateVehicle,
		{
			isLoading: updateVehicleLoading,
			error: updateVehicleError,
			isSuccess: updateVehicleSuccess,
			reset: resetUpdateVehicleState
		}
	] = useUpdateVehicleMutation();

	const societyConfig = useMemo<ISocietyConfigDetails | null>(() => {
		if (getSocietyConfigResponse && getSocietyConfigResponse.results.length > 0) {
			return getSocietyConfigResponse.results[0];
		}

		return null;
	}, [getSocietyConfigResponse]);

	const initialOverstayAlertInputs = useMemo<IOverstayAlertInputs>(() => {
		if (societyConfig && societyConfig.tat_config && societyConfig.tat_config.length > 0) {
			const tatConfig = societyConfig.tat_config[0];

			return {
				enabled: tatConfig.email_alerts.enabled,
				duration: String(tatConfig.email_alerts.overstay_duration)
			};
		}

		return {
			enabled: false,
			duration: ""
		};
	}, [societyConfig]);

	const vehicleAlertRowsData = useMemo<IVehicleAlertRowDetails[]>(() => {
		if (getVehiclesListResponse) {
			return getVehiclesListResponse.results.map((item, index) => ({
				id: item.id,
				sr_no: (vehicleAlertFilters.page - 1) * ROWS_PER_PAGE + index + 1,
				vehicle_number: item.number_plate
			}));
		}

		return [];
	}, [getVehiclesListResponse, vehicleAlertFilters.page]);

	const initialMailingListRowsData = useMemo<IMailingListDetails[]>(() => {
		if (societyConfig && societyConfig.mailing_list) {
			return societyConfig.mailing_list.map((item, index) => ({
				sr_no: index + 1,
				first_name: item.first_name,
				last_name: item.last_name,
				contact_number: item.contact_number,
				email_address: item.email_address,
				designation: item.designation
			}));
		}

		return [];
	}, [societyConfig]);

	function handleOpenAddToMailingListDialog() {
		setShowAddToMailingListDialog(true);
	}

	function handleCloseAddToMailingListDialog() {
		setShowAddToMailingListDialog(false);
		setAddToMailingListError("");
	}

	function handleOpenDeleteConfirmationDialog(mailingDetails: IMailingListDetails) {
		setSelectedMailingDetails(mailingDetails);
	}

	function handleCloseDeleteConfirmationDialog() {
		setSelectedMailingDetails(null);
	}

	function handleDeleteMailingDetails() {
		if (selectedMailingDetails) {
			setMailingListRowsData((currentList) => {
				const updatedList = [...currentList];

				const index = updatedList.findIndex((item) => item.email_address === selectedMailingDetails.email_address);
				if (index >= 0) updatedList.splice(index, 1);

				return updatedList;
			});
		}

		handleCloseDeleteConfirmationDialog();
	}

	function handleChangeOverstayAlertEnabled(checked: boolean) {
		setOverstayAlertsInputs((currentInputs) => ({
			...currentInputs,
			enabled: checked
		}));
	}

	function handleChangeOverstayAlertDuration(value: string) {
		setOverstayAlertsInputs((currentInputs) => ({
			...currentInputs,
			duration: value
		}));
	}

	function handleOpenAddVehicleAlertDialog() {
		setShowAddVehicleAlertDialog(true);
	}

	function handleCloseAddVehicleAlertDialog() {
		setShowAddVehicleAlertDialog(false);
		refetchVehiclesList();
	}

	function handleChangeVehicleAlertSearchInput(event: ChangeEvent<HTMLInputElement>) {
		setVehicleAlertSearchInput(event.target.value);
	}

	function handleVehicleAlertSearchFormSubmit(event: FormEvent) {
		event.preventDefault();
		setVehicleAlertFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters };

			if (vehicleAlertSearchInput) updatedFilters.search = vehicleAlertSearchInput;
			else delete updatedFilters.search;

			return updatedFilters;
		});
	}

	function handleClearVehicleAlertSearchInput() {
		setVehicleAlertSearchInput("");
		setVehicleAlertFilters((currentFilters) => {
			const updatedFilters = { ...currentFilters };
			delete updatedFilters.search;
			return updatedFilters;
		});
	}

	function handleSubmitAddToMailingList(mailingDetails: IMailingInputDetails) {
		const updatedList = [...mailingListRowsData];

		const existingEmail = updatedList.find((item) => item.email_address === mailingDetails.email_address);

		if (existingEmail) {
			setAddToMailingListError("Email address already exists");
		} else {
			updatedList.push({
				sr_no: (maxBy(updatedList, "sr_no")?.sr_no ?? 0) + 1,
				first_name: mailingDetails.first_name,
				last_name: mailingDetails.last_name,
				contact_number: mailingDetails.contact_number,
				email_address: mailingDetails.email_address,
				designation: mailingDetails.designation
			});

			setAddToMailingListError("");
			setMailingListRowsData(updatedList);
			handleCloseAddToMailingListDialog();
		}
	}

	function handleSaveButtonClick() {
		if (societyConfig && societyConfig.tat_config && societyConfig.tat_config.length > 0) {
			console.log("societyConfig", societyConfig);

			editSocietyConfig({
				id: societyConfig.id,
				data: {
					tat_config: [
						{
							...societyConfig.tat_config[0],
							email_alerts: {
								enabled: overstayAlertsInputs.enabled,
								overstay_duration: Number(overstayAlertsInputs.duration)
							}
						}
					],
					mailing_list: mailingListRowsData.map((item) => ({
						first_name: item.first_name,
						last_name: item.last_name,
						contact_number: item.contact_number,
						email_address: item.email_address,
						designation: item.designation,
						is_subscribed: true
					}))
				}
			});
		}
	}

	function handlePageChange(event: GridPaginationModel) {
		setVehicleAlertFilters((currentFiltersState) => ({
			...currentFiltersState,
			page: event.page + 1
		}));
	}

	function handleOpenDeleteVehicleConfirmationDialog(vehicleDetails: IVehicleAlertRowDetails) {
		setSelectedVehicleDetails(vehicleDetails);
	}

	function handleCloseDeleteVehicleConfirmationDialog() {
		setSelectedVehicleDetails(null);
	}

	function handleConfirmDeleteVehicle() {
		if (selectedVehicleDetails) {
			updateVehicle({
				id: selectedVehicleDetails.id,
				data: {
					number_plate: selectedVehicleDetails.vehicle_number,
					send_alert: false,
					society: customerId
				}
			});
		}
	}

	useEffect(() => {
		if (societyConfig && societyConfig.tat_config && societyConfig.tat_config.length > 0) {
			const tatConfig = societyConfig.tat_config[0];
			setOverstayAlertsInputs({
				enabled: tatConfig.email_alerts.enabled,
				duration: String(tatConfig.email_alerts.overstay_duration)
			});
		}
	}, [societyConfig]);

	useEffect(() => {
		if (editSocietyConfigError) {
			dispatch(
				showFeedbackNotification({
					message:
						"data" in editSocietyConfigError && editSocietyConfigError.data
							? String(editSocietyConfigError.data)
							: "Something went wrong",
					severity: "error"
				})
			);

			resetEditSocietyConfigState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editSocietyConfigError]);

	useEffect(() => {
		if (editSocietyConfigSuccess) {
			dispatch(
				showFeedbackNotification({
					message: "Changes saved successfully",
					severity: "success"
				})
			);

			resetEditSocietyConfigState();
			refetchSocietyConfig();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editSocietyConfigSuccess]);

	useEffect(() => {
		if (updateVehicleError) {
			dispatch(
				showFeedbackNotification({
					message:
						"data" in updateVehicleError && updateVehicleError.data
							? String(updateVehicleError.data)
							: "Failed to remove vehicle number alert",
					severity: "error"
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateVehicleError]);

	useEffect(() => {
		if (updateVehicleSuccess) {
			handleCloseDeleteVehicleConfirmationDialog();
			resetUpdateVehicleState();
			refetchVehiclesList();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateVehicleSuccess]);

	useEffect(() => {
		if (societyConfig && societyConfig.mailing_list) {
			setMailingListRowsData(
				societyConfig.mailing_list.map((item, index) => ({
					sr_no: index + 1,
					first_name: item.first_name,
					last_name: item.last_name,
					contact_number: item.contact_number,
					email_address: item.email_address,
					designation: item.designation
				}))
			);
		}
	}, [societyConfig]);

	const mailingListColumnsData: GridColDef[] = [
		{
			field: "sr_no",
			headerName: "Sr. No.",
			width: 100,
			sortable: false
		},
		{
			field: "name",
			headerName: "Name",
			minWidth: 200,
			flex: 1,
			sortable: false,
			valueGetter: (params: GridValueGetterParams<IMailingListDetails>) =>
				`${params.row.first_name} ${params.row.last_name}`.trim()
		},
		{
			field: "contact_number",
			headerName: "Contact Number",
			minWidth: 150,
			flex: 1,
			sortable: false
		},
		{
			field: "email_address",
			headerName: "Email Address",
			flex: 1.5,
			minWidth: 220,
			sortable: false
		},
		{
			field: "designation",
			headerName: "Designation",
			flex: 1,
			minWidth: 150,
			sortable: false
		},
		{
			field: "actions",
			headerName: "",
			width: 50,
			sortable: false,
			renderCell: (params: GridRenderCellParams<IMailingListDetails>) => (
				<Box className="reporting-table-actions-column-cell">
					<IconButton size="small" color="error" onClick={() => handleOpenDeleteConfirmationDialog(params.row)}>
						<DeleteIcon fontSize="inherit" />
					</IconButton>
				</Box>
			)
		}
	];

	const vehicleAlertColumnsData: GridColDef[] = [
		{
			field: "sr_no",
			headerName: "Sr. No.",
			width: 100,
			sortable: false
		},
		{
			field: "vehicle_number",
			headerName: "Vehicle Number",
			minWidth: 200,
			flex: 1,
			sortable: false
		},
		{
			field: "actions",
			headerName: "",
			width: 50,
			sortable: false,
			renderCell: (params: GridRenderCellParams<IVehicleAlertRowDetails>) => (
				<Box className="reporting-table-actions-column-cell">
					<IconButton size="small" color="error" onClick={() => handleOpenDeleteVehicleConfirmationDialog(params.row)}>
						<DeleteIcon fontSize="inherit" />
					</IconButton>
				</Box>
			)
		}
	];

	return (
		<Box className="reporting-tab-wrapper">
			<Box className="reporting-title-wrapper">
				<Typography variant="h6" color="var(--color-primary-main)">
					Mailing List
				</Typography>
			</Box>

			<Box className="reporting-tab-header-wrapper">
				<Typography variant="body2" color="#00000080">
					An email update will be sent at 9:00 AM daily.
				</Typography>

				<Divider sx={{ marginY: "0.5rem", borderColor: "var(--color-text-primary)" }} />
			</Box>

			<Box className="reporting-tab-actions-wrapper">
				<Button
					variant="outlined"
					color="success"
					size="small"
					startIcon={<AddCircleIcon />}
					sx={{ paddingX: 2 }}
					onClick={handleOpenAddToMailingListDialog}
				>
					Add
				</Button>
			</Box>

			<Box className="reporting-table-wrapper">
				<DataGrid
					columns={mailingListColumnsData}
					rows={mailingListRowsData}
					disableRowSelectionOnClick
					disableColumnMenu
					rowSpacingType="border"
					density="compact"
					columnHeaderHeight={60}
					pageSizeOptions={[ROWS_PER_PAGE]}
					slots={{ pagination: ReportingPagination }}
					slotProps={{ footer: { sx: { justifyContent: "center" } } }}
					hideFooter={mailingListRowsData.length <= ROWS_PER_PAGE}
					loading={getSocietyConfigLoading || getSocietyConfigFetching}
					getRowId={(row) => row.email_address}
					classes={{
						root: "table-root",
						columnHeader: "table-column-header",
						columnSeparator: "table-column-separator",
						virtualScroller: "table-virtual-scroller"
					}}
				/>
			</Box>

			<Box className="reporting-title-wrapper">
				<Typography variant="h6" color="var(--color-primary-main)">
					Overstay Alerts
				</Typography>

				<Switch
					checked={overstayAlertsInputs.enabled}
					onChange={(_event, checked) => handleChangeOverstayAlertEnabled(checked)}
				/>
			</Box>

			<Grid container spacing={2}>
				<Grid item xs={12} md={4}>
					<InputLabel
						htmlFor="duration-input"
						disabled={!overstayAlertsInputs.enabled}
						sx={{ color: "#3d3d3d", marginBottom: "0.5rem" }}
					>
						Duration
					</InputLabel>

					<NumberInput
						fullWidth
						positiveOnly
						disableSigned
						disableDecimal
						size="small"
						variant="outlined"
						id="duration-input"
						placeholder="Enter duration"
						value={overstayAlertsInputs.duration}
						disabled={!overstayAlertsInputs.enabled}
						onChange={(event) => handleChangeOverstayAlertDuration(event.target.value)}
						InputProps={{
							endAdornment: <InputAdornment position="end">minutes</InputAdornment>
						}}
					/>
				</Grid>

				<Grid item xs={12}>
					<LoadingButton
						disableElevation
						variant="contained"
						color="success"
						startIcon={<SaveIcon />}
						disabled={
							isEqual(initialMailingListRowsData, mailingListRowsData) &&
							isEqual(initialOverstayAlertInputs, overstayAlertsInputs)
						}
						onClick={handleSaveButtonClick}
						loadingPosition="start"
						loading={editSocietyConfigLoading}
					>
						Save Changes
					</LoadingButton>
				</Grid>
			</Grid>

			<Typography variant="h6" color="var(--color-primary-main)">
				Vehicle Number Alerts
			</Typography>

			<Box className="reporting-tab-actions-wrapper">
				<Button
					variant="outlined"
					color="success"
					size="small"
					startIcon={<AddCircleIcon />}
					sx={{ paddingX: 2 }}
					onClick={handleOpenAddVehicleAlertDialog}
				>
					Add
				</Button>

				<Box component="form" noValidate onSubmit={handleVehicleAlertSearchFormSubmit}>
					<TextField
						size="small"
						variant="filled"
						placeholder="Search"
						value={vehicleAlertSearchInput}
						onChange={handleChangeVehicleAlertSearchInput}
						InputProps={{
							hiddenLabel: true,
							disableUnderline: true,
							classes: { root: "filled-input-root" },
							endAdornment: (
								<InputAdornment position="end">
									{vehicleAlertFilters.search ? (
										<IconButton edge="end" size="small" onClick={handleClearVehicleAlertSearchInput}>
											<CloseIcon fontSize="inherit" />
										</IconButton>
									) : null}

									<IconButton edge="end" color="primary" size="small" type="submit">
										<SearchIcon />
									</IconButton>
								</InputAdornment>
							)
						}}
					/>
				</Box>
			</Box>

			<Box className="reporting-table-wrapper">
				<DataGrid
					columns={vehicleAlertColumnsData}
					rows={vehicleAlertRowsData}
					disableRowSelectionOnClick
					disableColumnMenu
					rowSpacingType="border"
					density="compact"
					columnHeaderHeight={60}
					pageSizeOptions={[ROWS_PER_PAGE]}
					onPaginationModelChange={handlePageChange}
					slots={{ pagination: ReportingPagination }}
					slotProps={{ footer: { sx: { justifyContent: "center" } } }}
					hideFooter={(getVehiclesListResponse?.count ?? 0) <= ROWS_PER_PAGE}
					loading={getVehiclesListLoading || getVehiclesListFetching}
					classes={{
						root: "table-root",
						columnHeader: "table-column-header",
						columnSeparator: "table-column-separator",
						virtualScroller: "table-virtual-scroller"
					}}
				/>
			</Box>

			<AddToMailingListDialog
				open={showAddToMailingListDialog}
				error={addToMailingListError}
				onClose={handleCloseAddToMailingListDialog}
				onSubmit={handleSubmitAddToMailingList}
			/>

			<ConfirmationDialog
				open={!!selectedMailingDetails}
				title="Delete From Mailing List"
				heading="Are you sure you want to remove this email from mailing list?"
				onClose={handleCloseDeleteConfirmationDialog}
				onConfirm={handleDeleteMailingDetails}
				width="sm"
				color="error"
			/>

			<ConfirmationDialog
				open={!!selectedVehicleDetails}
				title="Remove Vehicle Number Alert"
				heading="Are you sure you want to remove alert for the vehicle number?"
				onClose={handleCloseDeleteVehicleConfirmationDialog}
				onConfirm={handleConfirmDeleteVehicle}
				confirmButtonLoading={updateVehicleLoading}
				width="sm"
				color="error"
			/>

			<AddVehicleAlertDialog
				open={showAddVehicleAlertDialog}
				customerId={customerId}
				onClose={handleCloseAddVehicleAlertDialog}
			/>
		</Box>
	);
};

export default ReportingTab;
