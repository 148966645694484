import React, { FC, useEffect } from "react";
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	InputLabel,
	TextField,
	Typography
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import NumberInput from "../../../components/NumberInput";
import { VALIDATION_REGEX } from "../../../utils/constants";

export interface IMailingInputDetails {
	first_name: string;
	last_name: string;
	contact_number: string;
	email_address: string;
	designation: string;
}

interface IAddToMailingListDialogProps {
	open: boolean;
	error?: string;
	onClose: () => void;
	onSubmit: (mailingDetails: IMailingInputDetails) => void;
}

const AddToMailingListDialog: FC<IAddToMailingListDialogProps> = (props) => {
	const { open, error, onClose, onSubmit } = props;

	const {
		reset,
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<IMailingInputDetails>();

	function handleFormSubmit(formData: IMailingInputDetails) {
		onSubmit(formData);
	}

	useEffect(() => {
		if (open) {
			reset({
				first_name: "",
				last_name: "",
				contact_number: "",
				email_address: "",
				designation: ""
			});
		}
	}, [open, reset]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
			<DialogTitle textAlign="center" color="white" classes={{ root: "dialog-title-root primary" }}>
				Add to Mailing List
				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={onClose}>
						<Close />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				<Grid container spacing={2} component="form" noValidate onSubmit={handleSubmit(handleFormSubmit)}>
					<Grid item xs={12} md={6}>
						<InputLabel
							required
							htmlFor="first-name-input"
							error={!!errors.first_name}
							sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
						>
							First Name
						</InputLabel>

						<TextField
							required
							fullWidth
							size="small"
							id="first-name-input"
							variant="filled"
							placeholder="Add first name of the person"
							InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
							error={!!errors.first_name}
							helperText={errors.first_name && errors.first_name.message ? errors.first_name.message.toString() : ""}
							{...register("first_name", { required: "First name is required" })}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<InputLabel
							required
							htmlFor="last-name-input"
							error={!!errors.last_name}
							sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
						>
							Last Name
						</InputLabel>

						<TextField
							required
							fullWidth
							size="small"
							id="last-name-input"
							variant="filled"
							placeholder="Add last name of the person"
							InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
							error={!!errors.last_name}
							helperText={errors.last_name && errors.last_name.message ? errors.last_name.message.toString() : ""}
							{...register("last_name", { required: "Last name is required" })}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<InputLabel
							required
							htmlFor="contact-number-input"
							error={!!errors.contact_number}
							sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
						>
							Contact Number
						</InputLabel>

						<NumberInput
							isContactNumber
							required
							fullWidth
							size="small"
							id="contact-number-input"
							variant="filled"
							placeholder="Add contact number"
							InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
							error={!!errors.contact_number}
							helperText={
								errors.contact_number && errors.contact_number.message ? errors.contact_number.message.toString() : ""
							}
							{...register("contact_number", {
								required: "Contact number is required",
								pattern: { value: VALIDATION_REGEX.CONTACT_NUMBER, message: "Invalid contact number" }
							})}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<InputLabel
							required
							htmlFor="email-address-input"
							error={!!errors.email_address}
							sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
						>
							Email Address
						</InputLabel>

						<TextField
							required
							fullWidth
							size="small"
							id="email-address-input"
							variant="filled"
							placeholder="Add email address"
							InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
							error={!!errors.email_address}
							helperText={
								errors.email_address && errors.email_address.message ? errors.email_address.message.toString() : ""
							}
							{...register("email_address", { required: "Email address is required" })}
						/>
					</Grid>

					<Grid item xs={12} md={6}>
						<InputLabel
							htmlFor="designation-input"
							error={!!errors.designation}
							sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}
						>
							Designation
						</InputLabel>

						<TextField
							fullWidth
							size="small"
							id="designation-input"
							variant="filled"
							placeholder="Add designation"
							InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "filled-input-root" } }}
							error={!!errors.designation}
							helperText={errors.designation && errors.designation.message ? errors.designation.message.toString() : ""}
							{...register("designation")}
						/>
					</Grid>

					{error ? (
						<Grid item xs={12}>
							<Typography variant="body2" color="var(--color-error-main)" textAlign="center">
								{error}
							</Typography>
						</Grid>
					) : null}

					<Grid item xs={12}>
						<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
							<Button disableElevation variant="contained" color="success" type="submit" sx={{ paddingX: 4 }}>
								Save
							</Button>
						</Box>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default AddToMailingListDialog;
