import { Close } from "@mui/icons-material";
import {
	Autocomplete,
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	InputLabel,
	TextField,
	Typography
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { IGetVehiclesListRequestData, IGetVehiclesListResponseData } from "../../../types";
import { useGetVehiclesListQuery, useUpdateVehicleMutation } from "../../../redux/reducers/settings.reducer";
import { throttle } from "lodash";
import { LoadingButton } from "@mui/lab";

interface IAddVehicleAlertDialog {
	open: boolean;
	customerId: number;
	onClose: () => void;
}

const AddVehicleAlertDialog: FC<IAddVehicleAlertDialog> = (props) => {
	const { open, customerId, onClose } = props;

	const [selectedVehicle, setSelectedVehicle] = useState<IGetVehiclesListResponseData | null>(null);
	const [filters, setFilters] = useState<IGetVehiclesListRequestData>({ page: 1, society: customerId });

	// APIS
	// GET VEHICLES LIST
	const {
		data: getVehiclesListResponse,
		isLoading: getVehiclesListLoading,
		isFetching: getVehiclesListFetching
	} = useGetVehiclesListQuery(filters, {
		skip: !filters.search || filters.society < 0,
		refetchOnMountOrArgChange: true
	});

	// UPDATE VEHICLE
	const [
		updateVehicle,
		{
			isLoading: updateVehicleLoading,
			error: updateVehicleError,
			isSuccess: updateVehicleSuccess,
			reset: resetUpdateVehicleState
		}
	] = useUpdateVehicleMutation();

	const throttledGetVehiclesAPICall = throttle(
		(search: string) => {
			setFilters((currentFilters) => ({
				...currentFilters,
				search: search.trim()
			}));
		},
		500,
		{ leading: false }
	);

	function handleChangeSelectedVehicle(updatedValue: IGetVehiclesListResponseData | null) {
		setSelectedVehicle(updatedValue);
	}

	function handleSaveButtonClick() {
		if (selectedVehicle) {
			updateVehicle({
				id: selectedVehicle.id,
				data: {
					send_alert: true,
					number_plate: selectedVehicle.number_plate,
					society: customerId
				}
			});
		}
	}

	useEffect(() => {
		if (updateVehicleSuccess) {
			onClose();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateVehicleSuccess]);

	useEffect(() => {
		if (open) {
			setSelectedVehicle(null);
			setFilters({ page: 1, society: customerId });
		} else {
			resetUpdateVehicleState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
			<DialogTitle textAlign="center" classes={{ root: "dialog-title-root primary" }}>
				Add Vehicle Alert
				<Box className="close-dialog-icon-wrapper">
					<IconButton color="inherit" onClick={onClose}>
						<Close />
					</IconButton>
				</Box>
			</DialogTitle>

			<DialogContent classes={{ root: "dialog-content-root" }}>
				<InputLabel htmlFor="vehicle-number-input" sx={{ fontWeight: 500, color: "#424242", marginBottom: "0.5rem" }}>
					Vehicle Number
				</InputLabel>

				<Autocomplete
					value={selectedVehicle}
					onChange={(_event, updatedValue) => handleChangeSelectedVehicle(updatedValue)}
					options={getVehiclesListResponse && filters.search ? getVehiclesListResponse.results : []}
					getOptionKey={(item) => item.id}
					getOptionLabel={(item) => item.number_plate}
					componentsProps={{
						paper: { sx: { borderRadius: 0, borderBottomRightRadius: 6, borderBottomLeftRadius: 6 } }
					}}
					loading={getVehiclesListLoading || getVehiclesListFetching}
					noOptionsText={filters.search ? "No options" : "Start typing to search..."}
					getOptionDisabled={(option) => option.send_alert}
					filterOptions={(x) => x}
					onInputChange={(_event, value) => throttledGetVehiclesAPICall(value)}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							size="small"
							hiddenLabel
							id="vehicle-number-input"
							variant="filled"
							placeholder="Add vehicle number"
							InputProps={{
								...params.InputProps,
								hiddenLabel: true,
								disableUnderline: true,
								classes: { root: "filled-input-root" }
							}}
							classes={{ root: "vehicle-number-input-root" }}
						/>
					)}
				/>

				{updateVehicleError ? (
					<Box>
						<Typography variant="body2" color="var(--color-error-main)" textAlign="center">
							{"data" in updateVehicleError && updateVehicleError.data
								? String(updateVehicleError.data)
								: "Something went wrong"}
						</Typography>
					</Box>
				) : null}

				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "1rem" }}>
					<LoadingButton
						disableElevation
						variant="contained"
						color="success"
						sx={{ paddingX: 4 }}
						onClick={handleSaveButtonClick}
						loading={updateVehicleLoading}
					>
						Save
					</LoadingButton>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export default AddVehicleAlertDialog;
