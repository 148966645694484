import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography
} from "@mui/material";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch } from "../../../redux";
import { useEditSocietyConfigMutation, useGetSocietyConfigQuery } from "../../../redux/reducers/settings.reducer";
import { ISocietyConfigDetails } from "../../../types";
import { isEqual } from "lodash";
import { showFeedbackNotification } from "../../../redux/reducers/feedbackNotification.reducer";
import {
	Add,
	AddCircle,
	Apartment,
	ArrowLeft,
	ArrowRight,
	CheckBox,
	CheckBoxOutlineBlank,
	Close,
	DeleteOutline,
	Save
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import AddFlatDialog from "../../../components/SocietyConfiguration/AddFlatDialog";

interface ICustomFieldsTabProps {
	customerId: number;
}

interface ISelectedCustomFieldInputs {
	name: string;
	type: "dropdown" | "custom";
	entries: string[];
	new_field: boolean;
}

type ICustomFieldInputErrors = {
	[key in keyof Omit<ISelectedCustomFieldInputs, "entries" | "new_field">]: string;
};

const NEW_FIELD_NAME = "New Custom Field";

const CUSTOM_FIELDS_LIMIT = 5;
const FIELD_TYPES_LIMIT = 50;

const CustomFieldsTab: FC<ICustomFieldsTabProps> = (props) => {
	const { customerId } = props;

	const dispatch = useAppDispatch();

	const customFieldsListRef = useRef<HTMLDivElement>(null);

	const [customFieldsList, setCustomFieldsList] = useState<ISelectedCustomFieldInputs[]>([]);
	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const [selectedEntriesIndex, setSelectedEntriesIndex] = useState<number[]>([]);
	const [entriesSelectionEnabled, setEntriesSelectionEnabled] = useState<boolean>(false);
	const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState<"single" | "all" | null>(null);
	const [showAddEntryDialog, setShowAddEntryDialog] = useState<boolean>(false);
	const [selectedCustomFieldInputs, setSelectedCustomFieldInputs] = useState<ISelectedCustomFieldInputs>({
		name: "",
		type: "dropdown",
		entries: [],
		new_field: false
	});
	const [inputErrors, setInputErrors] = useState<ICustomFieldInputErrors>({
		name: "",
		type: ""
	});

	// APIS
	// GET SOCIETY CONFIG
	const { data: getSocietyConfigResponse, refetch: refetchSocietyConfig } = useGetSocietyConfigQuery(customerId, {
		skip: customerId < 0
	});

	// EDIT SOCIETY CONFIG
	const [
		editSocietyConfig,
		{
			isLoading: editSocietyConfigLoading,
			error: editSocietyConfigError,
			isSuccess: editSocietyConfigSuccess,
			reset: resetEditSocietyConfigState
		}
	] = useEditSocietyConfigMutation();

	const societyDetails = useMemo<ISocietyConfigDetails | null>(() => {
		if (getSocietyConfigResponse && getSocietyConfigResponse.results.length > 0) {
			return getSocietyConfigResponse.results[0];
		}

		return null;
	}, [getSocietyConfigResponse]);

	const initialCustomFieldsList = useMemo<ISelectedCustomFieldInputs[]>(() => {
		if (!societyDetails) return [];
		if (!societyDetails.custom_fields) return [];

		return societyDetails.custom_fields.map((item) => ({
			name: item.name,
			type: item.type,
			entries: [...item.entries],
			new_field: false
		}));
	}, [societyDetails]);

	const initialSelectedCustomFieldInputs = useMemo<ISelectedCustomFieldInputs>(() => {
		const customFieldDetails = initialCustomFieldsList[selectedIndex];

		if (customFieldDetails) return customFieldDetails;

		return {
			name: "",
			type: "dropdown",
			entries: [],
			new_field: false
		};
	}, [initialCustomFieldsList, selectedIndex]);

	const isSaveButtonDisabled = useMemo<boolean>(() => {
		const selectedCustomFieldDetails = customFieldsList[selectedIndex];

		if (!selectedCustomFieldDetails) return true;
		if (entriesSelectionEnabled) return true;
		if (!selectedCustomFieldInputs.name || !selectedCustomFieldInputs.type) return true;

		if (selectedCustomFieldInputs.new_field && selectedCustomFieldInputs.name === NEW_FIELD_NAME) return true;

		if (isEqual(selectedCustomFieldInputs, initialSelectedCustomFieldInputs)) return true;

		return false;
	}, [
		customFieldsList,
		entriesSelectionEnabled,
		initialSelectedCustomFieldInputs,
		selectedCustomFieldInputs,
		selectedIndex
	]);

	const isPreviewDisabled = useMemo<boolean>(() => {
		if (customFieldsList.length <= 0) return true;
		if (selectedCustomFieldInputs.type === "custom") return true;

		return false;
	}, [customFieldsList.length, selectedCustomFieldInputs.type]);

	function handleOpenAddEntryDialog() {
		setShowAddEntryDialog(true);
	}

	function handleCloseAddEntryDialog() {
		setShowAddEntryDialog(false);
	}

	function handleOpenDeleteConfirmationDialog(type: "single" | "all") {
		setShowDeleteConfirmationDialog(type);
	}

	function handleCloseDeleteConfirmationDialog() {
		setShowDeleteConfirmationDialog(null);
	}

	function handleSubmitDeleteCustomFields() {
		if (showDeleteConfirmationDialog === "single") {
			if (selectedIndex >= 0) {
				const updatedCustomFieldsList = [...customFieldsList];
				updatedCustomFieldsList.splice(selectedIndex, 1);
				setCustomFieldsList(updatedCustomFieldsList);

				setSelectedIndex((currentIndex) => (currentIndex > 0 ? currentIndex - 1 : 0));

				if (societyDetails) {
					editSocietyConfig({
						id: societyDetails.id,
						data: {
							custom_fields: updatedCustomFieldsList.map((item) => ({
								name: item.name,
								type: item.type,
								entries: item.entries
							}))
						}
					});
				}
			}
		} else if (showDeleteConfirmationDialog === "all") {
			setCustomFieldsList([]);
			setSelectedIndex(0);

			if (societyDetails) {
				editSocietyConfig({
					id: societyDetails.id,
					data: {
						custom_fields: []
					}
				});
			}
		}

		handleCloseDeleteConfirmationDialog();
	}

	function handleScrollCustomFieldsList(scrollTo: "left" | "right") {
		if (!customFieldsListRef.current) return;

		const scrollValue = scrollTo === "left" ? -300 : scrollTo === "right" ? 300 : 0;
		customFieldsListRef.current.scrollTo({ left: scrollValue, behavior: "smooth" });
	}

	function handleChangeSelectedCustomField(index: number) {
		setSelectedIndex(index);
		setSelectedEntriesIndex([]);
	}

	function handleAddButtonClick() {
		const updatedCustomFieldsList = [...customFieldsList];

		const updatedLength = updatedCustomFieldsList.push({
			name: NEW_FIELD_NAME,
			type: "dropdown",
			entries: [],
			new_field: true
		});

		setCustomFieldsList(updatedCustomFieldsList);
		setSelectedIndex(updatedLength - 1);
	}

	function handleEnableEntriesSelection() {
		setEntriesSelectionEnabled(true);
	}

	function handleDisableEntriesSelection() {
		setEntriesSelectionEnabled(false);
		setSelectedEntriesIndex([]);
	}

	function handleChangeSelectedCustomFieldName(value: string) {
		setSelectedCustomFieldInputs((currentInputs) => ({
			...currentInputs,
			name: value
		}));
	}

	function handleChangeSelectedCustomFieldType(value: "dropdown" | "custom") {
		setSelectedCustomFieldInputs((currentInputs) => ({
			...currentInputs,
			type: value
		}));
	}

	function handleToggleEntrySelection(index: number) {
		setSelectedEntriesIndex((currentEntries) => {
			if (currentEntries.includes(index)) {
				return currentEntries.filter((item) => item !== index);
			}

			return [...currentEntries, index];
		});
	}

	function handleSaveButtonClick() {
		const updatedInputErrors: ICustomFieldInputErrors = {
			name: "",
			type: ""
		};

		if (!selectedCustomFieldInputs.name) {
			updatedInputErrors.name = "Custom field name is required";
		}

		if (!selectedCustomFieldInputs.type) {
			updatedInputErrors.type = "Type of custom field is required";
		}

		setInputErrors(updatedInputErrors);

		if (updatedInputErrors.name || updatedInputErrors.type) return;

		if (selectedIndex < 0) return;

		const updatedCustomEntriesList = [...customFieldsList];

		updatedCustomEntriesList[selectedIndex] = {
			name: selectedCustomFieldInputs.name,
			type: selectedCustomFieldInputs.type,
			entries: selectedCustomFieldInputs.entries,
			new_field: false
		};

		setCustomFieldsList(updatedCustomEntriesList);

		if (societyDetails) {
			editSocietyConfig({
				id: societyDetails.id,
				data: {
					custom_fields: updatedCustomEntriesList.map((item) => ({
						name: item.name,
						type: item.type,
						entries: item.entries
					}))
				}
			});
		}
	}

	function handleRemoveButtonClick() {
		setSelectedCustomFieldInputs((currentInputs) => ({
			...currentInputs,
			entries: currentInputs.entries.filter((_, index) => !selectedEntriesIndex.includes(index))
		}));

		handleDisableEntriesSelection();
	}

	function handleSubmitAddEntry(entry: string) {
		const updatedInputs = { ...selectedCustomFieldInputs };

		if (updatedInputs.entries.includes(entry)) {
			dispatch(
				showFeedbackNotification({
					message: "The entered field type already exists",
					severity: "warning"
				})
			);

			return;
		}

		updatedInputs.entries.push(entry);
		setSelectedCustomFieldInputs(updatedInputs);

		handleCloseAddEntryDialog();
	}

	useEffect(() => {
		if (societyDetails && societyDetails.custom_fields) {
			setCustomFieldsList(
				societyDetails.custom_fields.map((item) => ({
					name: item.name,
					type: item.type,
					entries: [...item.entries],
					new_field: false
				}))
			);
		}
	}, [societyDetails]);

	useEffect(() => {
		if (customFieldsList.length > 0 && selectedIndex >= 0) {
			const customFieldDetails = customFieldsList[selectedIndex];

			if (customFieldDetails) {
				setSelectedCustomFieldInputs(customFieldDetails);
			}
		}
	}, [customFieldsList, selectedIndex]);

	useEffect(() => {
		if (editSocietyConfigSuccess) {
			resetEditSocietyConfigState();
			refetchSocietyConfig();
			dispatch(
				showFeedbackNotification({
					message: "Custom fields updated successfully",
					severity: "success"
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editSocietyConfigSuccess]);

	useEffect(() => {
		if (editSocietyConfigError) {
			dispatch(
				showFeedbackNotification({
					message:
						"data" in editSocietyConfigError && editSocietyConfigError.data
							? String(editSocietyConfigError.data)
							: "Failed to update custom fields",
					severity: "error"
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editSocietyConfigError]);

	return (
		<Box className="society-configuration-screen-wrapper">
			<Box className="building-configuration-component-wrapper">
				<Box className="buildings-list-wrapper">
					<Box className="buildings-list-scroll-arrow left" onClick={() => handleScrollCustomFieldsList("left")}>
						<ArrowLeft />
					</Box>

					<Box className="buildings-list" ref={customFieldsListRef}>
						<Box
							sx={{
								overflowX: "auto",
								msOverflowStyle: "none",
								scrollbarWidth: "none",
								display: "flex",
								flexWrap: "nowrap",
								gap: "0.75rem"
							}}
						>
							{customFieldsList.map((customFieldItem, index) => (
								<Box className="buildings-list-item" key={index}>
									<Button
										disableElevation
										size="small"
										variant="contained"
										color="primary"
										startIcon={<Apartment />}
										sx={{ textTransform: "none" }}
										classes={{ root: selectedIndex !== index ? "inactive" : "" }}
										onClick={() => handleChangeSelectedCustomField(index)}
									>
										{customFieldItem.name}
									</Button>
								</Box>
							))}
						</Box>

						{customFieldsList.length < CUSTOM_FIELDS_LIMIT ? (
							<Box className="buildings-list-item">
								<Button
									size="small"
									variant="outlined"
									color="primary"
									startIcon={<Add />}
									onClick={handleAddButtonClick}
									sx={{ textTransform: "capitalize" }}
									disabled={customFieldsList.some((item) => item.name === NEW_FIELD_NAME || item.new_field)}
								>
									Add New Custom Field
								</Button>
							</Box>
						) : null}
					</Box>

					<Box className="buildings-list-scroll-arrow right" onClick={() => handleScrollCustomFieldsList("right")}>
						<ArrowRight />
					</Box>
				</Box>

				<Box className="building-inputs-wrapper">
					<Grid container spacing={2}>
						<Grid item xs={12} md={3}>
							<InputLabel
								htmlFor="custom-field-name-input"
								sx={{ fontWeight: 500, color: "var(--color-primary-dark)", marginBottom: "0.5rem" }}
							>
								Custom Field Name
							</InputLabel>

							<TextField
								fullWidth
								size="small"
								variant="filled"
								id="custom-field-name-input"
								placeholder="Add field name"
								value={selectedCustomFieldInputs.name}
								onChange={(event) => handleChangeSelectedCustomFieldName(event.target.value)}
								error={!!inputErrors.name}
								helperText={inputErrors.name}
								InputProps={{ hiddenLabel: true, disableUnderline: true, classes: { root: "building-input-root" } }}
								inputProps={{ style: { textTransform: "capitalize" } }}
								disabled={customFieldsList.length <= 0}
								onFocus={(event) => {
									if (event.target.value === NEW_FIELD_NAME) event.target.select();
								}}
							/>
						</Grid>

						<Grid item xs={12} md={3}>
							<InputLabel
								htmlFor="type-input"
								sx={{ fontWeight: 500, color: "var(--color-primary-dark)", marginBottom: "0.5rem" }}
							>
								Type of Custom Field Input
							</InputLabel>

							<FormControl
								fullWidth
								size="small"
								variant="filled"
								error={!!inputErrors.type}
								hiddenLabel
								id="type-input"
								disabled={customFieldsList.length <= 0}
							>
								<Select
									fullWidth
									disableUnderline
									placeholder="Add type of custom field"
									value={selectedCustomFieldInputs.type}
									onChange={(event) => handleChangeSelectedCustomFieldType(event.target.value as "dropdown" | "custom")}
									classes={{ root: "building-input-root" }}
								>
									<MenuItem value="dropdown">Multiple Choice</MenuItem>
									<MenuItem value="custom">Text Input</MenuItem>
								</Select>

								{inputErrors.type ? <FormHelperText>{inputErrors.type}</FormHelperText> : null}
							</FormControl>
						</Grid>
					</Grid>
				</Box>

				<Typography variant="h6" color="var(--color-primary-dark)">
					Preview of Custom Field
				</Typography>

				<Box className="building-config-actions-wrapper">
					<Button
						variant="outlined"
						color="success"
						size="small"
						startIcon={<AddCircle />}
						onClick={handleOpenAddEntryDialog}
						disabled={
							entriesSelectionEnabled ||
							isPreviewDisabled ||
							selectedCustomFieldInputs.entries.length >= FIELD_TYPES_LIMIT
						}
					>
						Add Field Type
					</Button>

					{entriesSelectionEnabled ? (
						<>
							<Button
								variant="outlined"
								color="primary"
								size="small"
								startIcon={<Close />}
								onClick={handleDisableEntriesSelection}
								sx={{ minWidth: "100px" }}
								disabled={isPreviewDisabled}
							>
								Cancel
							</Button>

							<Button
								variant="outlined"
								color="error"
								size="small"
								startIcon={<DeleteOutline />}
								disabled={selectedEntriesIndex.length <= 0 || isPreviewDisabled}
								onClick={handleRemoveButtonClick}
							>
								Remove
							</Button>
						</>
					) : (
						<Button
							variant="outlined"
							color="primary"
							size="small"
							startIcon={<CheckBoxOutlineBlank />}
							onClick={handleEnableEntriesSelection}
							sx={{ minWidth: "100px" }}
							disabled={isPreviewDisabled}
						>
							Select
						</Button>
					)}
				</Box>

				<Box className="buildings-flats-preview-wrapper">
					<Box className="buildings-flats-preview extra-small-width">
						{selectedCustomFieldInputs.entries.map((item, index) => (
							<Box key={index} className={`building-flats-preview-item ${isPreviewDisabled ? "inactive" : ""}`}>
								{entriesSelectionEnabled ? (
									<Box className="icon-wrapper" onClick={() => handleToggleEntrySelection(index)}>
										{selectedEntriesIndex.includes(index) ? (
											<CheckBox fontSize="inherit" />
										) : (
											<CheckBoxOutlineBlank fontSize="inherit" />
										)}
									</Box>
								) : null}

								<Typography color="inherit" variant="body2" textAlign="center">
									{item}
								</Typography>
							</Box>
						))}
					</Box>
				</Box>

				<Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "1rem" }}>
					<Button
						variant="outlined"
						color="error"
						startIcon={<DeleteOutline />}
						disabled={customFieldsList.length <= 0}
						onClick={() => handleOpenDeleteConfirmationDialog("all")}
					>
						Delete All
					</Button>

					{customFieldsList.length > 1 ? (
						<Button
							variant="outlined"
							color="error"
							startIcon={<DeleteOutline />}
							onClick={() => handleOpenDeleteConfirmationDialog("single")}
						>
							Delete
						</Button>
					) : null}

					<LoadingButton
						disableElevation
						variant="contained"
						color="success"
						startIcon={<Save />}
						sx={{ paddingX: 3 }}
						onClick={handleSaveButtonClick}
						disabled={isSaveButtonDisabled}
						loading={editSocietyConfigLoading}
						loadingPosition="start"
					>
						Save
					</LoadingButton>
				</Box>

				<ConfirmationDialog
					open={!!showDeleteConfirmationDialog}
					title={`Delete Custom Field${showDeleteConfirmationDialog === "all" ? "s" : ""}`}
					heading={`Are you sure you want to delete ${
						showDeleteConfirmationDialog === "all" ? "all the custom fields?" : "this custom field?"
					}`}
					onClose={handleCloseDeleteConfirmationDialog}
					onConfirm={handleSubmitDeleteCustomFields}
					width="sm"
					color="error"
				/>

				<AddFlatDialog
					open={showAddEntryDialog}
					allowChars
					title="Add Field Type"
					inputLabel="Field Type"
					onClose={handleCloseAddEntryDialog}
					onSubmit={handleSubmitAddEntry}
				/>
			</Box>
		</Box>
	);
};

export default CustomFieldsTab;
