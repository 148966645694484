import { getAPIHeaderOptions, getDefaultParams, transformErrorResponse } from "../../utils/apiUtils";
import {
	ICreateSocietyRequestData,
	IEditSocietyRequestData,
	IGetAllCustomersResponseDetails,
	IGetSocietyConfigResponseData,
	IGetVehiclesListRequestData,
	IGetVehiclesListResponse,
	ISocietyConfigDetails,
	IUpdateVehicleRequestData
} from "../../types";
import { queriesApi } from "./queries.reducer";

export const settings = queriesApi.injectEndpoints({
	endpoints: (builder) => ({
		// GET SOCIETY CONFIG
		getSocietyConfig: builder.query<IGetSocietyConfigResponseData, number>({
			query: (societyId) => ({
				url: "/analytics/admin/society",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: { society: societyId }
			})
		}),

		// EDIT SOCIETY CONFIG
		editSocietyConfig: builder.mutation<ISocietyConfigDetails, IEditSocietyRequestData>({
			query: (requestData) => ({
				url: `/analytics/admin/society/${requestData.id}`,
				headers: getAPIHeaderOptions(true),
				method: "PATCH",
				body: { ...getDefaultParams(), ...requestData.data }
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// CREATE SOCIETY
		createSociety: builder.mutation<ISocietyConfigDetails, ICreateSocietyRequestData>({
			query: (requestData) => ({
				url: "/analytics/admin/society",
				headers: getAPIHeaderOptions(true),
				method: "POST",
				body: { ...getDefaultParams(), ...requestData }
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// GET ALL CUSTOMERS
		getAllCustomers: builder.query<IGetAllCustomersResponseDetails[], void>({
			query: () => ({
				url: "/analytics/admin/list-societies",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: getDefaultParams()
			})
		}),

		// GET VEHICLES LIST
		getVehiclesList: builder.query<IGetVehiclesListResponse, IGetVehiclesListRequestData>({
			query: (requestData) => ({
				url: "/analytics/vehicle",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: requestData
			})
		}),

		// UPDATE VEHICLE
		updateVehicle: builder.mutation<unknown, IUpdateVehicleRequestData>({
			query: (requestData) => ({
				url: `/analytics/vehicle/${requestData.id}`,
				headers: getAPIHeaderOptions(true),
				method: "PATCH",
				body: requestData.data
			})
		})
	})
});

export const {
	// GET SOCIETY CONFIG
	useGetSocietyConfigQuery,
	// EDIT SOCIETY CONFIG
	useEditSocietyConfigMutation,
	// CREATE SOCIETY
	useCreateSocietyMutation,
	// GET ALL CUSTOMERS
	useGetAllCustomersQuery,
	// GET VEHICLES LIST
	useGetVehiclesListQuery,
	// UPDATE VEHICLE
	useUpdateVehicleMutation
} = settings;
