import {
	ICreateUserRequestData,
	IEditUserRequestData,
	IGetUsersMetricsResponse,
	IGetUsersRequestData,
	IGetUsersResponse
} from "../../types";
import { getAPIHeaderOptions, getDefaultParams, transformErrorResponse } from "../../utils/apiUtils";
import { queriesApi } from "./queries.reducer";

export const usersApi = queriesApi.injectEndpoints({
	endpoints: (builder) => ({
		// GET USERS
		getUsers: builder.query<IGetUsersResponse, IGetUsersRequestData>({
			query: (requestData) => ({
				url: "/auth/admin/users",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: { ...getDefaultParams(), ...requestData }
			})
		}),

		// GET USERS METRICS
		getUsersMetrics: builder.query<IGetUsersMetricsResponse, void>({
			query: () => ({
				url: "/auth/admin/user-metrics",
				headers: getAPIHeaderOptions(true),
				method: "GET",
				params: getDefaultParams()
			})
		}),

		// CREATE USER
		createUser: builder.mutation<unknown, ICreateUserRequestData>({
			query: (requestData) => ({
				url: "/auth/admin/users-data",
				headers: getAPIHeaderOptions(true),
				method: "POST",
				body: requestData
			}),
			transformErrorResponse: transformErrorResponse
		}),

		// EDIT USER
		editUser: builder.mutation<unknown, IEditUserRequestData>({
			query: (requestData) => ({
				url: `/auth/admin/users-data/${requestData.id}`,
				headers: getAPIHeaderOptions(true),
				method: "PATCH",
				body: requestData.data
			}),
			transformErrorResponse: transformErrorResponse
		})
	})
});

export const {
	// GET USERS
	useGetUsersQuery,
	// GET USERS METRICS
	useGetUsersMetricsQuery,
	// CREATE USER
	useCreateUserMutation,
	// EDIT USER
	useEditUserMutation
} = usersApi;
